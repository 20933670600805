import { CheckYourSymptoms } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { IoArrowForwardOutline } from "react-icons/io5";

export const SectionCheckYourSymptoms = ({
  className,
  data,
  slug,
  dataDefault,
  classMainColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: CheckYourSymptoms | undefined;
  slug?: string | undefined;
  dataDefault?: CheckYourSymptoms | undefined;
  classMainColor: string | undefined;
}) => {
  return (
    <div className={classNames("p-10", className ?? "")} {...rest}>
      {data && (
        <div className="flex flex-col-reverse items-center justify-between lg:container lg:mx-auto lg:flex-row">
          <div className="flex flex-col items-center lg:items-start">
            <h1
              className={classNames(
                "w-full rounded-2xl py-4 text-center text-[28px] font-medium sm:text-5xl lg:text-left"
              )}>
              <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
            </h1>
            <p className={classNames("w-full rounded-2xl py-4 text-center text-lg font-light lg:text-left")}>
              <Trans i18nKey={data?.description ?? dataDefault?.description ?? ""} />
            </p>
            <Link
              href={data.signInLink}
              target="_blank"
              className={classNames(
                "relative mt-4 inline-flex min-h-[51px] w-full items-center justify-center gap-2 rounded-full py-2 pl-14 pr-20 text-base font-light text-white transition-colors duration-300 sm:w-fit ",
                classMainColor,
                themeColors[slug as keyof typeof themeColors].hoverBg
              )}>
              <Trans i18nKey="service_4_section_6_button_text" />
              <IoArrowForwardOutline className="text-xl" />
            </Link>
          </div>
          <Image
            className={classNames("rounded-2xl")}
            src={data?.img ?? ""}
            alt={data?.title ?? dataDefault?.title ?? ""}
            width="454"
            height="342"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
        </div>
      )}
    </div>
  );
};
