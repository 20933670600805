import { AppContext } from "@/context/AppContextProvider";
import { useContext } from "react";

function useAppContext() {
  if (!AppContext) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return useContext(AppContext);
}

export default useAppContext;
