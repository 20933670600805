import { getCoordinates, getDistance } from "@/lib/api/apiGoogleMaps";
import { MapCoordinates } from "@/lib/types/mapCoordinates";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import classNames from "classnames";
import getConfig from "next/config";
import React, { useEffect, useState } from "react";

const {
  publicRuntimeConfig: { API_GOOGLE_MAPS_KEY },
} = getConfig();

export const Map = ({
  className,
  addresses,
  currentLocationAddress,
  setDistances,
  setSelectedProvider,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  addresses: string[];
  currentLocationAddress: string;
  setDistances: any;
  setSelectedProvider: any;
}) => {
  const [coordinates, setCoordinates] = useState<MapCoordinates[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    version: "weekly",
    googleMapsApiKey: API_GOOGLE_MAPS_KEY,
  });

  useEffect(() => {
    const calculateDistance = async (coordinates: MapCoordinates[]) => {
      const distances: string[] = await Promise.all(
        coordinates.map((coordinate) => getDistance(currentLocationAddress, coordinate.address))
      );
      setDistances(isLoaded);
      setDistances(distances);
    };

    const fetchCoordinates = async () => {
      const coordinates: MapCoordinates[] = await getCoordinates(addresses);
      setCoordinates(coordinates);
      await calculateDistance(coordinates);
    };
    if (isLoaded) {
      if (addresses.length) {
        fetchCoordinates();
      } else {
        setCoordinates([]);
        setDistances([]);
      }
    }
  }, [isLoaded, addresses]);

  const onClickMarker = (index: number) => setSelectedProvider(index);

  return isLoaded ? (
    <div className={classNames("", className ?? "")} {...rest}>
      <GoogleMap
        center={{ lat: coordinates[0]?.lat || 0, lng: coordinates[0]?.lng || 0 }}
        zoom={6}
        mapContainerStyle={{ width: "100%", height: "66vh", borderRadius: "24px" }}>
        {coordinates.map((coordinate, index) => (
          <MarkerF
            key={index}
            position={{ lat: coordinate.lat, lng: coordinate.lng }}
            onClick={() => onClickMarker(index)}
          />
        ))}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};
