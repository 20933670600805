import { SectionBehavioralHealthBenefits } from "@/components/service-sections/SectionBehavioralHealthBenefits";
import { SectionBehavioralHealthForms } from "@/components/service-sections/SectionBehavioralHealthForms";
import { SectionCards } from "@/components/service-sections/SectionCards";
import { SectionCheckOutOurMemberVideo } from "@/components/service-sections/SectionCheckOutOurMemberVideo";
import { SectionCheckYourSymptoms } from "@/components/service-sections/SectionCheckYourSymptoms";
import { SectionEverythingYouNeedIsSentToYou } from "@/components/service-sections/SectionEverythingYouNeedIsSentToYou";
import { SectionFormSearchForAProvider } from "@/components/service-sections/SectionFormSearchForAProvider";
import { SectionFrequentlyAskedQuestions } from "@/components/service-sections/SectionFrequentlyAskedQuestions";
import { SectionHealthCoachesGuide } from "@/components/service-sections/SectionHealthCoachesGuide";
import { SectionMainInfo } from "@/components/service-sections/SectionMainInfo";
import { SectionOurProgramsInclude } from "@/components/service-sections/SectionOurProgramsInclude";
import { SectionQuicklyEliminateBackAndJointPain } from "@/components/service-sections/SectionQuicklyEliminateBackAndJointPain";
import { SectionTabs } from "@/components/service-sections/SectionTabs";
import { SectionUsingYourBehavioralHealthBenefits } from "@/components/service-sections/SectionUsingYourBehavioralHealthBenefits";
import { ClientService } from "@/lib/types";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

export const Services = ({
  className,
  service,
  serviceDefault,
  clientSlug,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  service?: ClientService | undefined;
  serviceDefault?: ClientService | undefined;
  clientSlug: string | undefined;
}) => {
  const [classBgColor, setClassBgColor] = useState("bg-brand-bg-section-blue");
  const [classMainColor, setClassMainColor] = useState("bg-brand-blue-800");
  const [classTextColor, setClassTextColor] = useState("text-brand-text-blue-dark");
  const [classFillColor, setClassFillColor] = useState("fill-brand-text-blue-dark");
  const [classStrokeColor, setClassStrokeColor] = useState("stroke-brand-text-blue-dark");
  const [classBorderColor, setClassBorderColor] = useState("border-brand-text-blue-dark");
  const [classGroupHoverColor, setClassGroupHoverColor] = useState("group-hover:bg-brand-text-blue-dark");

  useEffect(() => {
    const setColors = () => {
      switch (service?.slug) {
        case "simpleeap":
          setClassBgColor("bg-brand-bg-section-purple");
          setClassMainColor("bg-brand-blue-800");
          setClassTextColor("text-brand-text-blue-dark");
          setClassFillColor("fill-brand-text-blue-dark");
          setClassStrokeColor("stroke-brand-text-blue-dark");
          setClassBorderColor("border-brand-text-blue-dark");
          setClassGroupHoverColor("group-hover:bg-brand-text-blue-dark");
          break;
        case "simplewellbeing":
          setClassBgColor("bg-brand-bg-section-pink");
          setClassMainColor("bg-brand-red-900");
          setClassTextColor("text-brand-text-maroon");
          setClassFillColor("fill-brand-text-maroon");
          setClassStrokeColor("stroke-brand-text-maroon");
          setClassBorderColor("border-brand-text-maroon");
          setClassGroupHoverColor("group-hover:bg-brand-text-maroon");
          break;
        case "simplebehavioral":
          setClassBgColor("bg-brand-bg-section-green");
          setClassMainColor("bg-brand-green-600");
          setClassTextColor("text-brand-text-green");
          setClassFillColor("fill-brand-text-green");
          setClassStrokeColor("stroke-brand-text-green");
          setClassBorderColor("border-brand-text-green");
          setClassGroupHoverColor("group-hover:bg-brand-text-green");
          break;
        case "simplemsk-virtual":
        case "simplemsk-in-person":
          setClassBgColor("bg-brand-bg-section-orange");
          setClassMainColor("bg-brand-orange-600");
          setClassTextColor("text-brand-text-orange");
          setClassFillColor("fill-brand-orange-600");
          setClassStrokeColor("stroke-brand-orange-600");
          setClassBorderColor("border-brand-orange-600");
          setClassGroupHoverColor("group-hover:bg-brand-orange-600");
          break;
      }
    };

    setColors();
  }, [service]);
  return (
    <div className={classNames("w-full", className ?? "")} {...rest}>
      {service?.sections &&
        Object.keys(service?.sections)?.map((key, index) => {
          if (service?.sections && serviceDefault?.sections) {
            switch (key) {
              case "mainInfo":
                return (
                  <SectionMainInfo
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    slug={service.slug}
                    classBgColor={classBgColor}
                    classMainColor={classMainColor}
                    classTextColor={classTextColor}
                    classFillColor={classFillColor}
                  />
                );
              case "cardsBlock":
                return (
                  <>
                    {service?.slug != "simplemsk-virtual" && (
                      <SectionCards
                        key={index}
                        slug={service.slug}
                        data={service?.sections[key]}
                        dataDefault={serviceDefault?.sections[key]}
                        classBgColor={classBgColor}
                        classMainColor={classMainColor}
                        classTextColor={classTextColor}
                        classFillColor={classFillColor}
                      />
                    )}
                  </>
                );
              case "healthCoachesGuide":
                return (
                  <SectionHealthCoachesGuide
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classMainColor={classMainColor}
                  />
                );
              case "behavioralHealthBenefits":
                return (
                  <SectionBehavioralHealthBenefits
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classBgColor={classBgColor}
                    classTextColor={classTextColor}
                  />
                );
              case "usingYourBehavioralHealthBenefits":
                return (
                  <SectionUsingYourBehavioralHealthBenefits
                    key={index}
                    slug={service.slug}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classMainColor={classMainColor}
                  />
                );
              case "behavioralHealthForms":
                return (
                  <SectionBehavioralHealthForms
                    key={index}
                    slug={service.slug}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classBgColor={classBgColor}
                    classTextColor={classTextColor}
                    clientSlug={clientSlug}
                  />
                );
              case "searchForAProvider":
                return (
                  <SectionFormSearchForAProvider
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classMainColor={classMainColor}
                    slug={service?.slug}
                  />
                );
              case "quicklyEliminateBackAndJointPain":
                return (
                  <>
                    <SectionQuicklyEliminateBackAndJointPain
                      key={index}
                      data={service?.sections[key]}
                      dataDefault={serviceDefault?.sections[key]}
                      classBgColor={classBgColor}
                      classMainColor={classMainColor}
                      classBorderColor={classBorderColor}
                      classTextColor={classTextColor}
                      classGroupHoverColor={classGroupHoverColor}
                    />
                    {service?.slug == "simplemsk-virtual" &&
                      Object.keys(service?.sections)?.map((key2, index2) => {
                        if (service?.sections && serviceDefault?.sections && key2 == "cardsBlock") {
                          return (
                            <SectionCards
                              key={index2}
                              slug={service.slug}
                              data={service?.sections[key2]}
                              dataDefault={serviceDefault?.sections[key2]}
                              classBgColor={classBgColor}
                              classMainColor={classMainColor}
                              classTextColor={classTextColor}
                              classFillColor={classFillColor}
                            />
                          );
                        }
                      })}
                    {service?.slug == "simplemsk-virtual" &&
                      Object.keys(service?.sections)?.map((key2, index2) => {
                        if (
                          service?.sections &&
                          serviceDefault?.sections &&
                          key2 == "checkOutOurMemberVideo"
                        ) {
                          return (
                            <SectionCheckOutOurMemberVideo
                              key={index2}
                              data={service?.sections[key2]}
                              dataDefault={serviceDefault?.sections[key2]}
                            />
                          );
                        }
                      })}
                    {service?.slug == "simplemsk-virtual" &&
                      Object.keys(service?.sections)?.map((key2, index2) => {
                        if (service?.sections && serviceDefault?.sections && key2 == "ourProgramsInclude") {
                          return (
                            <SectionOurProgramsInclude
                              key={index2}
                              slug={service?.slug}
                              data={service?.sections[key2]}
                              dataDefault={serviceDefault?.sections[key2]}
                            />
                          );
                        }
                      })}
                  </>
                );
              case "ourProgramsInclude":
                return (
                  <>
                    {service?.slug != "simplemsk-virtual" && (
                      <SectionOurProgramsInclude
                        key={index}
                        slug={service?.slug}
                        data={service?.sections[key]}
                        dataDefault={serviceDefault?.sections[key]}
                      />
                    )}
                  </>
                );
              case "checkOutOurMemberVideo":
                return (
                  <>
                    {service?.slug != "simplemsk-virtual" && (
                      <SectionCheckOutOurMemberVideo
                        key={index}
                        data={service?.sections[key]}
                        dataDefault={serviceDefault?.sections[key]}
                      />
                    )}
                  </>
                );
              case "everythingYouNeedIsSentToYou":
                return (
                  <SectionEverythingYouNeedIsSentToYou
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classMainColor={classMainColor}
                  />
                );
              case "checkYourSymptoms":
                return (
                  <SectionCheckYourSymptoms
                    key={index}
                    slug={service.slug}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classMainColor={classMainColor}
                  />
                );
              case "frequentlyAskedQuestions":
                return (
                  <SectionFrequentlyAskedQuestions
                    key={index}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classBgColor={classBgColor}
                    classBorderColor={classBorderColor}
                    classTextColor={classTextColor}
                    classStrokeColor={classStrokeColor}
                  />
                );
              case "tabs":
                return (
                  <SectionTabs
                    key={index}
                    clientSlug={clientSlug}
                    slug={service.slug}
                    data={service?.sections[key]}
                    dataDefault={serviceDefault?.sections[key]}
                    classBgColor={classBgColor}
                    classBorderColor={classBorderColor}
                    classMainColor={classMainColor}
                    classTextColor={classTextColor}
                    classFillColor={classFillColor}
                  />
                );
              default:
                return null;
            }
          }
        })}
    </div>
  );
};
