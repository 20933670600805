import { ExportProvidersToPDF } from "@/components/ui-components/ExportProvidersToPDF";
import { Map } from "@/components/ui-components/Map";
// import { useInView } from "react-intersection-observer";
import { Pagination } from "@/components/ui-components/Pagination";
import { ProviderCard } from "@/components/ui-components/ProviderCard";
import { Spinner } from "@/components/ui-components/Spinner";
import { getCurrentLocationAddress } from "@/lib/api/apiGoogleMaps";
import { getProviders, getSpeciality } from "@/lib/api/apiProvіders";
import {
  ProviderSearchResult,
  Provider,
  SearchForAProvider,
  SearchProvider,
  SearchSpeciality,
} from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import { useJsApiLoader } from "@react-google-maps/api";
import classNames from "classnames";
import { Trans, useTranslation } from "next-i18next";
import getConfig from "next/config";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoArrowForwardOutline } from "react-icons/io5";

const {
  publicRuntimeConfig: { API_GOOGLE_MAPS_KEY },
} = getConfig();

export const SectionFormSearchForAProvider = ({
  className,
  data,
  dataDefault,
  classMainColor,
  slug,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: SearchForAProvider | undefined;
  dataDefault?: SearchForAProvider | undefined;
  classMainColor: string | undefined;
  slug: string | undefined;
}) => {
  const { t } = useTranslation();
  const [searchProvider, setSearchProvider] = useState<SearchProvider>();
  const [providers, setProviders] = useState<Provider[]>([]);
  const [totalProviders, setTotalProviders] = useState<number>(0);
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([]);
  const [distances, setDistances] = useState<string[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<number>(-1);
  const [isSearch, setIsSearch] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [phoneForQuestions, setPhoneForQuestions] = useState<string>("");
  const [currentLocationAddress, setCurrentLocation] = useState<string>("");
  const [isZipCodeRequired, setIsZipCodeRequired] = useState<boolean>(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    version: "weekly",
    googleMapsApiKey: API_GOOGLE_MAPS_KEY,
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      clinic: "",
      category: "0",
      miles: "10",
      zip_code: "",
    },
  });

  useEffect(() => {
    setPhoneForQuestions(data?.network == "physical" ? "18775198839" : "18884254800");

    const getSpecialityList = async () => {
      const searchSpeciality: SearchSpeciality = {
        plan_id: data?.planId ?? "",
        network: data?.network ?? "",
        limit: 100,
        offset: 0,
      };

      const specialities: string[] = await getSpeciality(searchSpeciality);
      setSpecialities(specialities);
    };

    getSpecialityList();
    if (selectedProvider != -1) scrollToHash(selectedProvider);
  }, [data, distances, addresses, selectedProvider, providers]);

  function formatPhoneNumber(phoneNumber: string) {
    return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
  }

  const scrollToHash = (element_id: number) => {
    document.querySelectorAll(".provider-card").forEach((item) => item.classList.remove("shadow-2xl"));
    const element = document.getElementById(`scroll-${element_id}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    element?.classList.add("shadow-2xl");
  };

  const searchProviders = async (searchParams: FieldValues) => {
    setCurrentPage(0);
    setIsSearch(1);
    const searchProvider: SearchProvider = {
      Name: searchParams.first_name + " " + searchParams.last_name,
      Clinic: searchParams.clinic,
      plan_id: data?.planId ?? "",
      network: data?.network ?? "",
      limit: 10,
      offset: 0,
      range: parseInt(searchParams.miles),
    };

    if (searchParams.category != "0") {
      searchProvider.speciality_1 = specialities[parseInt(searchParams.category) - 1];
    }

    if (searchParams.zip_code.length) {
      searchProvider.zip_code = searchParams.zip_code;
      setCurrentLocation(searchParams.zip_code);
    } else {
      let isLocationDetected: boolean = false;
      if (isLoaded) {
        isLocationDetected = await detectCurrentLocationAddress();
      }
      if (!isLocationDetected) {
        setIsSearch(0);
        return;
      }
    }

    setSearchProvider(searchProvider);
    const providerSearchResult: ProviderSearchResult = await getProviders(searchProvider);
    setCurrentPage(1);
    setProviders(providerSearchResult.providers);
    setTotalProviders(providerSearchResult.total_records);
    getAddressesFromProvidersArray(providerSearchResult.providers);
    setIsSearch(0);
  };

  const detectCurrentLocationAddress = async () => {
    const currentLocationAddress: string = await getCurrentLocationAddress();
    if (currentLocationAddress != "") {
      setCurrentLocation(currentLocationAddress);
      return true;
    } else {
      setIsZipCodeRequired(true);
      setTimeout(function () {
        trigger("zip_code");
      }, 500);
      return false;
    }
  };

  const getPage = async (page: number) => {
    if (!searchProvider) return;
    const offset = page * searchProvider?.limit - 10;
    if (offset < totalProviders) {
      setIsSearch(1);
      searchProvider.offset = offset;
      setSearchProvider(searchProvider);
      const providerSearchResult: ProviderSearchResult = await getProviders(searchProvider);
      setProviders(providerSearchResult.providers);
      getAddressesFromProvidersArray(providerSearchResult.providers);
      setCurrentPage(page);
      setIsSearch(0);
    }
  };

  const getAddressesFromProvidersArray = (providers: Provider[]) => {
    const addressesNew: string[] = providers.map((provider) => {
      const address = provider.Address;
      return `${address.Address_1}, ${address.City}, ${address.State} ${address.Zip}`;
    });
    setAddresses(addressesNew);
  };

  return (
    <div className={classNames("", className ?? "")} {...rest}>
      <div className="px-4 py-5 md:container sm:my-10 sm:px-10 sm:py-10 md:mx-auto lg:my-20 ">
        <h1 className="text-center text-[28px] font-medium sm:text-5xl">
          <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
        </h1>
        <p className="mt-3 text-center text-base font-light sm:mt-6 sm:text-lg lg:px-20">
          <Trans i18nKey={data?.description1 ?? dataDefault?.description1 ?? ""} />
          <span className="font-semibold">
            <Trans i18nKey={data?.description2 ?? dataDefault?.description2 ?? ""} />
          </span>
          <Trans i18nKey={data?.description3 ?? dataDefault?.description3 ?? ""} />
        </p>
        <form
          className="form-search-for-a-provider mt-6 sm:mt-12"
          onSubmit={handleSubmit((data) => searchProviders(data))}>
          <div className="grid gap-4 sm:grid-cols-2">
            <div>
              <input
                className="form-input"
                {...register("first_name", { required: false })}
                defaultValue=""
                placeholder={t(data?.firstName ?? dataDefault?.firstName ?? "")}
              />
              {errors.first_name && (
                <p className="error-msg">
                  <Trans i18nKey="this_field_is_required" />
                </p>
              )}
            </div>
            <div>
              <input
                className="form-input"
                {...register("last_name", { required: false })}
                defaultValue=""
                placeholder={t(data?.lastName ?? dataDefault?.lastName ?? "")}
              />
              {errors.last_name && (
                <p className="error-msg">
                  <Trans i18nKey="this_field_is_required" />
                </p>
              )}
            </div>
          </div>
          <div>
            <input
              className="form-input"
              {...register("clinic", { required: false })}
              defaultValue=""
              placeholder={t(data?.clinic ?? dataDefault?.clinic ?? "")}
            />
            {errors.clinic && (
              <p className="error-msg">
                <Trans i18nKey="this_field_is_required" />
              </p>
            )}
          </div>
          <div>
            <select className="form-input form-select" {...register("category")} defaultValue="0">
              <option key={0} value="0">
                <Trans i18nKey={data?.selectCategory ?? dataDefault?.selectCategory ?? ""} />
              </option>
              {specialities.map((speciality, index) => (
                <option key={index + 1} value={index + 1}>
                  {speciality}
                </option>
              ))}
            </select>
          </div>
          <div className="grid gap-4 sm:grid-cols-2">
            <div>
              <select className="form-input form-select" {...register("miles")}>
                {[...Array(10)]
                  .map((_, i) => i + 1)
                  .map((i) => (
                    <option key={i} value={(i * 10).toString()}>
                      {i * 10 + " "}
                      <Trans i18nKey={data?.miles ?? dataDefault?.miles ?? ""} />
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <input
                className="form-input"
                {...register("zip_code", { pattern: /^[a-zA-Z0-9]{5,6}$/, required: isZipCodeRequired })}
                defaultValue=""
                placeholder={t(data?.zipcode ?? dataDefault?.zipcode ?? "")}
              />
              {errors.zip_code && (
                <p className="error-msg">
                  <Trans i18nKey="invalid_zipcode" />
                </p>
              )}
            </div>
          </div>
          <div
            className={classNames(
              "flex items-center",
              currentPage == 0 && isSearch == 1 ? "justify-between" : "justify-end"
            )}>
            {currentPage == 0 && isSearch == 1 && <Spinner />}
            <button
              className={classNames(
                "bg-brand-bg-main relative float-right mt-4 inline-flex min-h-[51px] w-full items-center justify-center gap-1 rounded-full py-2 pl-14 pr-20 text-base font-light text-white transition-colors duration-300 sm:w-fit",
                slug == "simplebehavioral" ? "bg-brand-blue-900 hover:bg-brand-blue-300" : classMainColor,
                slug !== "simplebehavioral" && themeColors[slug as keyof typeof themeColors].hoverBg
              )}
              type="submit">
              <Trans i18nKey="submit" defaults="Submit" />
              <IoArrowForwardOutline />
            </button>
          </div>
        </form>
        {providers.length > 0 && data?.hasOwnProperty("phone") && data?.phone != "" && (
          <div className={classNames("mt-10 rounded-xl bg-brand-green-400 px-8 py-10")}>
            <p className="text-left text-lg font-light">
              <Trans i18nKey={data?.messagePart1 ?? dataDefault?.messagePart1 ?? ""} />
              <a className="pl-2 text-xl font-light text-brand-text-blue-light" href={"tel:" + data?.phone}>
                {data?.phone}
              </a>
            </p>
            <p className="text-left text-lg font-light">
              <Trans i18nKey="form_search_for_a_provider_msg_part_2" />
            </p>
          </div>
        )}
      </div>
      {providers.length > 0 && (
        <div className="container mt-10 bg-brand-bg-section-blue p-4 sm:p-8">
          <h2 className="text-center text-xl font-semibold sm:text-3xl-custom">
            <Trans i18nKey="map_search_results" />
          </h2>
          <div className={classNames("mt-4 rounded-xl bg-brand-bg-light px-4 py-8 sm:p-8")}>
            <div className="flex">
              <IoMdInformationCircleOutline className="mr-0.5" />
              <h4 className="text-left text-lg font-semibold">
                <Trans i18nKey="map_about_your_search_results" />
              </h4>
            </div>

            <ul className="ml-7 list-disc">
              <li>
                <p className="text-left text-sm font-light">
                  <Trans i18nKey="map_this_list_is_updated_daily" />
                </p>
              </li>
              <li>
                <p className="text-left text-sm font-light">
                  <Trans i18nKey="map_your_results_contains_providers" />
                </p>
              </li>
            </ul>
            <p className="mt-2 text-left text-sm font-light">
              <Trans i18nKey="map_if_you_have_any_questions" />
              <a
                className="pl-2 text-sm font-semibold text-brand-text-blue"
                href={"tel:" + phoneForQuestions}>
                {formatPhoneNumber(phoneForQuestions)}
              </a>
              .
            </p>
          </div>

          <div className=" mt-4 grid gap-x-10 sm:mt-8 lg:grid-cols-2">
            <Map
              addresses={addresses}
              currentLocationAddress={currentLocationAddress}
              setDistances={setDistances}
              setSelectedProvider={setSelectedProvider}
            />
            <div className="mt-4 sm:mt-8 lg:mt-0">
              {(totalProviders > 0 || isSearch == 0) && (
                <div className="mb-4 flex items-center justify-between sm:mb-6">
                  <p className="text-base font-semibold sm:text-2xl">
                    {totalProviders + " "}
                    <Trans i18nKey={data?.providersFound ?? dataDefault?.providersFound ?? ""} />
                  </p>
                  {searchProvider && (
                    <ExportProvidersToPDF
                      currentLocationAddress={currentLocationAddress}
                      searchProvider={searchProvider}
                      totalProviders={totalProviders}
                      phoneForQuestions={formatPhoneNumber(phoneForQuestions)}
                    />
                  )}
                </div>
              )}
              {providers.map((provider: Provider, index) => (
                <ProviderCard key={index} provider={provider} index={index} distance={distances[index]} />
              ))}
              {isSearch == 1 && (
                <div>
                  <Spinner className="flex justify-center" />
                </div>
              )}
            </div>
          </div>
          <Pagination
            totalPages={searchProvider ? Math.round(totalProviders / searchProvider?.limit) : 0}
            currentPage={currentPage}
            getPage={getPage}
          />
        </div>
      )}
    </div>
  );
};
