import { ProviderSearchResult, SearchProvider, SearchSpeciality } from "@/lib/types";
import axios from "axios";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { API_PROVIDER_URL, API_PROVIDER_KEY }, // Available both client and server side
} = getConfig();

const api = axios.create({
  baseURL: API_PROVIDER_URL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "x-api-key": API_PROVIDER_KEY,
  },
});

export async function getProviders(params: SearchProvider): Promise<ProviderSearchResult> {
  const providerSearchResult: ProviderSearchResult = await new Promise((resolve) => {
    api
      .get("/get-providers", {
        params: params,
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const providerSearchResult: ProviderSearchResult = {
          providers: [],
          total_records: 0,
          last_update: "",
        };
        resolve(providerSearchResult);
        console.log(error.response);
      });
  });
  return providerSearchResult;
}

export function getSpeciality(params: SearchSpeciality): Promise<string[]> {
  return new Promise((resolve, reject) => {
    api
      .get("/get-providers-speciality_1", {
        params: params,
      })
      .then(async (response) => {
        resolve(response.data?.speciality);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
