import { ClientService } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React, { useRef, useState } from "react";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const Slider = ({
  className,
  clientSlug,
  services,
  ...rest
}: {
  className: string;
  clientSlug: string;
  services: ClientService[];
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const swiperRef = useRef<SwiperType>();
  const slideContent = {
    simpleeap: {
      title: "program_1_slide_title",
      description: "program_1_slide_description",
    },
    simplewellbeing: {
      title: "program_2_slide_title",
      description: "program_2_slide_description",
    },
    simplebehavioral: {
      title: "program_3_slide_title",
      description: "program_3_slide_description",
    },
    "simplemsk-virtual": {
      title: "program_4_slide_title",
      description: "program_4_slide_description",
    },
    "simplemsk-in-person": {
      title: "program_5_slide_title",
      description: "program_5_slide_description",
    },
  };

  return (
    <>
      <div
        className={`px-5 lg:px-0 ${activeSlide > 4 ? "xl:mr-[calc(50%-720px)]" : ""} ${activeSlide === 0 && "xl:ml-[calc(50%-720px)]"} transition-all duration-300`}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={3.9}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
            1200: {
              slidesPerView: 3.9,
              spaceBetween: 32,
            },
          }}
          pagination={{
            clickable: true,
            el: ".paginate",
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onInit={(swiper) => {
            setTotalSlides(swiper.slides.length);
          }}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.realIndex);
          }}
          className={classNames(
            className ?? "",
            "!pb-4 sm:!pb-16",
            totalSlides < 4 ? "center-slides" : "",
            "!pl-5",
            "!pr-5",
            activeSlide === 0 ? "xl:!pl-11" : "",
            activeSlide > 4 ? "xl:!pr-11" : ""
          )}
          {...rest}>
          {services.map(
            (service) =>
              service.isShow && (
                <SwiperSlide key={service.id} className="group !h-auto rounded-[16px]">
                  <Link
                    href={`/${clientSlug}/${service.slug}${service?.slide.hasOwnProperty("slug") ? "#" + service?.slide.slug : ""}`}>
                    <div className="flex h-full shrink flex-col items-center justify-center">
                      <div
                        className="mx-4 h-72 max-h-72 min-h-72 w-full rounded-[16px] bg-cover bg-center bg-no-repeat transition-all duration-300 group-hover:mb-4 group-hover:h-[265px] group-hover:min-h-[265px]  sm:mb-4"
                        style={{ backgroundImage: `url(${service.slide.img})` }}
                      />
                      <div
                        className={classNames(
                          themeColors[service.slug as keyof typeof themeColors]?.groupHoverBg,
                          "flex w-full shrink flex-col items-start justify-center rounded-xl p-4 pb-10 pt-4 transition-all duration-300 group-hover:pb-12 group-hover:!text-white sm:h-full"
                        )}>
                        <h3 className="mb-4 text-left text-[22px] font-semibold sm:text-2xl">
                          <Trans
                            i18nKey={
                              service?.slide?.title ??
                              (slideContent as any)[service.slug].title ??
                              "program_1_slide_title"
                            }
                          />
                        </h3>
                        <h3 className="mb-4 text-left text-base font-light">
                          <Trans
                            i18nKey={
                              service?.slide?.description ??
                              (slideContent as any)[service.slug].description ??
                              "program_1_slide_description"
                            }
                          />
                        </h3>
                        <button className="max-x-[314px] group mx-auto inline-flex w-full items-center justify-center gap-2 rounded-[48px] border border-brand-blue-900 px-4 py-2 text-[16px] font-medium transition-colors duration-300 group-hover:border-white group-hover:text-white sm:mt-auto">
                          <Trans i18nKey="button_learn_more" defaults="Learn More" />{" "}
                          <IoArrowForwardOutline className="text-[20px]" />
                        </button>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
      <div className="container relative flex items-center justify-between">
        <div className="swiper-pagination paginate !relative flex" />
        <div className={`${totalSlides < 4 ? "hidden" : "flex"}`}>
          <button
            className="group mr-3 flex h-10 w-10 rotate-180 items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300 hover:bg-brand-blue-900 hover:text-white"
            onClick={() => swiperRef.current?.slidePrev()}>
            <IoArrowForwardOutline />
          </button>
          <button
            className="group flex h-10 w-10 items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300 hover:bg-brand-blue-900 hover:text-white"
            onClick={() => swiperRef.current?.slideNext()}>
            <IoArrowForwardOutline />
          </button>
        </div>
      </div>
    </>
  );
};
