import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React, { useState, useEffect } from "react";

import LanguageSelector from "./LanguageSelector";

export const AppHeader = ({ logo, slug }: { logo: string | undefined; slug: string | undefined }) => {
  const [isScrollToBottom, setIsScrollToBottom] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrollToBottom(true);
    } else {
      setIsScrollToBottom(false);
    }
  };

  return (
    <header
      className={classNames(
        " container sticky top-0 z-10 w-full pb-4 pt-1 transition-all duration-300 sm:pb-12",
        isScrollToBottom ? "sm:pt-[2px]" : "sm:pt-[67px]"
      )}>
      <div className="flex w-full items-center justify-between rounded-[12px] bg-brand-bg-default p-2 px-4 shadow-nav sm:rounded-full sm:p-4 md:items-center md:px-14">
        <div className="flex w-full items-center justify-between gap-2 sm:justify-start sm:gap-0 md:items-center">
          <Link className="mr-[5px] flex self-center" href={slug ? `/${slug}` : "/"}>
            <img src="/images/logo.svg" alt="logo" width="157" height="24" />
          </Link>
          {logo && logo.length && (
            <div className="flex flex-col items-center gap-1 md:flex-row md:gap-8">
              <h1 className="text-left text-[12px] font-light leading-none sm:leading-3 md:text-xl ">
                {/*<Link href={slug ? `/go/${slug}` : "/"}>*/}
                <Trans i18nKey="provided_by" defaults="provided by" />
                {/*</Link>*/}
              </h1>
              <img className="max-h-20 object-contain" src={logo} alt="client-logo" width="157" height="24" />
            </div>
          )}
          <LanguageSelector className="block text-black sm:hidden" />
        </div>
        <LanguageSelector className="hidden text-black sm:block" />
      </div>
    </header>
  );
};
