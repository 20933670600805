import { File } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React from "react";

export const FileLink = ({
  className,
  file,
  slug,
  classFillColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  file: File;
  classFillColor: string | undefined;
  slug?: string | undefined;
}) => {
  if (file.title == "service_5_tab_52_block_2_file_1_title") {
    file.title = "service_5_tab_5_block_2_file_1_title";
  }

  return (
    <div className={classNames("mb-2 flex items-center")} {...rest}>
      <svg
        className={classFillColor}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3349_4747)">
          <path d="M20 0.25V16.0938H16.3438V19.75H0.5V3.90625H4.15625V0.25H20ZM18.7812 14.875V1.46875H5.375V3.90625H7.8125V5.125H1.71875V18.5312H15.125V12.4375H16.3438V14.875H18.7812ZM9.45972 11.6472L8.60278 10.7903L14.2585 5.125H10.25V3.90625H16.3438V10H15.125V5.99146L9.45972 11.6472Z" />
        </g>
        <defs>
          <clipPath id="clip0_3349_4747">
            <rect width="19.5" height="19.5" fill="white" transform="translate(0.5 0.25)" />
          </clipPath>
        </defs>
      </svg>
      <Link
        className={classNames(
          "relative ml-3 after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:transition-all after:duration-300 hover:after:w-full",
          className ?? "",
          slug === "simplebehavioral"
            ? "after:bg-white"
            : themeColors[slug as keyof typeof themeColors]?.afterBg
        )}
        href={`${file.link}`}
        target="_blank">
        <Trans i18nKey={file.title} />
      </Link>
    </div>
  );
};
