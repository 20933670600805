import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React, { useEffect, useState } from "react";

export const AccessFiles = ({
  clientSlug,
  slug,
  classTextColor,
}: {
  clientSlug: string | undefined;
  slug?: string | undefined;
  classTextColor: string | undefined;
}) => {
  const [inNetwork, setInNetwork] = useState<string | null>(null);
  const [outNetwork, setOutNetwork] = useState<string | null>(null);

  useEffect(() => {
    if (!inNetwork) setLink("inNetwork");
    if (!outNetwork) setLink("outNetwork");
  }, [inNetwork, outNetwork]);

  const setLink = async (type: string): Promise<string | null> => {
    const response = await fetch(
      `/api/get-access-file-url?slug=${slug}&clientSlug=${clientSlug}&type=${type}`
    );
    if (response.ok) {
      const link = await response.json();
      switch (type) {
        case "inNetwork":
          setInNetwork(link);
          break;
        case "outNetwork":
          setOutNetwork(link);
          break;
      }
      return link;
    }
    return null;
  };

  const handleDownload = async (fileURL: string | null, type: string) => {
    if (isUrlExpired(fileURL)) {
      fileURL = await setLink(type);
    }
    if (!fileURL) return;
    let fileName = fileURL.split("/").pop();
    if (!fileName) {
      console.error("Failed to extract the file name from the URL");
      return;
    }
    fileName = fileName.split("?")[0];
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isUrlExpired = (url: string | null): boolean => {
    if (!url) return false;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const expiresParam = urlParams.get("Expires");
    if (!expiresParam) {
      throw new Error("Expires parameter not found in the URL");
    }
    const expiresTimestamp = parseInt(expiresParam, 10);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > expiresTimestamp;
  };

  return (
    <>
      {(inNetwork || outNetwork) && (
        <p className={classNames("text-center text-xs font-light", `${classTextColor}`)}>
          <Trans i18nKey="access_files" defaults="Access Files" />:
          {inNetwork && (
            <Link
              className={classNames(
                `relative mx-1 cursor-pointer text-left !text-xs font-semibold after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:transition-all after:duration-300 hover:after:w-full sm:text-xl`,
                themeColors[slug as keyof typeof themeColors].afterBg
              )}
              href={`${inNetwork}`}
              onClick={(event) => {
                event.preventDefault();
                handleDownload(inNetwork, "inNetwork");
              }}
              target="_blank">
              <Trans i18nKey="in_network" defaults="In Network" />
            </Link>
          )}
          {inNetwork && outNetwork ? "|" : ""}
          {outNetwork && (
            <Link
              className={classNames(
                `relative ml-1 cursor-pointer text-left !text-xs font-semibold after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:transition-all after:duration-300 hover:after:w-full sm:text-xl`,
                themeColors[slug as keyof typeof themeColors].afterBg
              )}
              href={`${outNetwork}`}
              onClick={(event) => {
                event.preventDefault();
                handleDownload(outNetwork, "outNetwork");
              }}
              target="_blank">
              <Trans i18nKey="out_of_network" defaults="Out of Network" />
            </Link>
          )}
        </p>
      )}
    </>
  );
};
