import { BlockHeader } from "@/components/ui-components/BlockHeader";
import { CardsBlock } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { IoMdLock } from "react-icons/io";
import { IoArrowForwardOutline } from "react-icons/io5";

export const SectionCards = ({
  className,
  data,
  slug,
  dataDefault,
  classBgColor,
  classMainColor,
  classTextColor,
  classFillColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: CardsBlock | undefined;
  slug: string;
  dataDefault?: CardsBlock | undefined;
  classBgColor: string;
  classMainColor: string;
  classTextColor: string | undefined;
  classFillColor: string | undefined;
}) => {
  const groupHoverBgcolor = "group-hover:" + classMainColor;
  return (
    <div className={classNames(className ?? "")} {...rest}>
      {data && (
        <div
          className={classNames(
            "flex flex-col items-center justify-center pb-10 lg:pb-16",
            `${classBgColor}`
          )}>
          <div className="container flex flex-col items-center justify-center pt-2 sm:pt-8 md:px-[70px] lg:pt-16 lg2:px-[134px]">
            <BlockHeader
              title={data?.title ?? dataDefault?.title ?? ""}
              description={data?.description ?? dataDefault?.description ?? ""}
              classTextColor={classTextColor}
            />

            <div className="grid grid-cols-1 gap-3.5 sm:mb-[30px] sm:mt-10 sm:grid-cols-2 sm:gap-[30px] md:mb-[40px] md:grid-cols-3 lg:mb-[50px] lg:gap-[72px]">
              {data.cards.map((card, index) => (
                <div key={index} className="group">
                  <Image
                    className="h-[267px] max-h-[267px] min-h-[267px] w-full rounded-xl object-cover transition-all duration-300 group-hover:mb-2 group-hover:h-[230px] group-hover:min-h-[230px]"
                    src={card.img}
                    alt={card?.title ?? dataDefault?.cards[index]?.title ?? ""}
                    width="330"
                    height="267"
                    placeholder="blur"
                    blurDataURL="/images/blur.png"
                  />
                  <div
                    className={`${groupHoverBgcolor} rounded-[16px] px-4 pb-4 pt-2 transition-all duration-300 sm:pt-4 sm:group-hover:pb-10 md:group-hover:pb-20`}>
                    <h3
                      className={classNames(
                        "mt-1 text-left text-xl font-semibold transition-all duration-300 group-hover:mt-0 group-hover:text-white  sm:mt-[30px] sm:text-2xl",
                        `${classTextColor}`
                      )}>
                      <Trans i18nKey={card?.title ?? dataDefault?.cards[index]?.title ?? ""} />
                    </h3>
                    {(card.hasOwnProperty("description") ||
                      dataDefault?.cards[index]?.hasOwnProperty("description")) && (
                      <p className="text-left text-base font-light transition-all duration-300 group-hover:text-white sm:mt-[9px] sm:text-lg">
                        <Trans i18nKey={card.description ?? dataDefault?.cards[index]?.description ?? ""} />
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {data.hasOwnProperty("btnUrl") && data.btnUrl != "" && slug !== "simplewellbeing" && (
              <Link
                className={classNames(
                  "mt-6 flex w-full items-center justify-center gap-1 rounded-full px-[55px] py-3.5 text-base font-light text-white transition-colors duration-300 hover:bg-brand-blue-300 sm:w-fit",
                  `${classMainColor}`,
                  themeColors[slug as keyof typeof themeColors]?.hoverBg
                )}
                target="_blank"
                href={data.btnUrl}>
                <Trans i18nKey="get_started" defaults="Get Started" />
                <IoArrowForwardOutline className="text-[20px]" />
              </Link>
            )}

            {(data?.hasOwnProperty("lock") || dataDefault?.hasOwnProperty("lock")) && (
              <div className="mt-6 flex items-center justify-center">
                <IoMdLock className={classNames("mr-2 inline", `${classFillColor}`)} />
                <p
                  className={classNames(
                    "relative text-center text-xs font-light sm:text-xl",
                    `${classTextColor}`
                  )}>
                  <Trans i18nKey={data.lock} />
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
