import classNames from "classnames";
import React from "react";

export function ServicesScreen({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames("flex min-h-screen flex-col items-center", className)} {...rest}>
      <h1>All programs Screen</h1>
    </div>
  );
}
