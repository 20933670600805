import { EverythingYouNeedIsSentToYou } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React from "react";

export const SectionEverythingYouNeedIsSentToYou = ({
  className,
  data,
  dataDefault,
  classMainColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: EverythingYouNeedIsSentToYou | undefined;
  dataDefault?: EverythingYouNeedIsSentToYou | undefined;
  classMainColor: string | undefined;
}) => {
  return (
    <div className={classNames("p-10", className ?? "", classMainColor)} {...rest}>
      {data && (
        <div className="flex flex-col items-center lg:container lg:mx-auto lg:flex-row">
          <Image
            className={classNames("sm:order-0 order-1 rounded-3xl object-cover lg:mr-14 lg:h-full")}
            src={data?.img ?? ""}
            alt={data?.title ?? dataDefault?.title ?? ""}
            width="454"
            height="342"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
          <div className="order-0 sm:order-1">
            <h1
              className={classNames(
                "w-full rounded-2xl py-2 text-center text-[28px] font-medium text-brand-text-white sm:py-4 sm:text-5xl-custom lg:text-left"
              )}>
              <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
            </h1>
            <p
              className={classNames(
                "w-full rounded-2xl text-center text-lg font-light text-brand-text-white sm:py-4 lg:text-left"
              )}>
              <Trans i18nKey={data?.description ?? dataDefault?.description ?? ""} />
            </p>
            {/*<div className="item-center mb-6 flex flex-row flex-wrap justify-center gap-2 sm:mb-0">*/}
            {/*  {(data?.items ?? dataDefault?.items)?.map((item, index) => (*/}
            {/*    <div key={index} className="flex flex-col items-center justify-between">*/}
            {/*      <Image*/}
            {/*        className={classNames("h-[100px] w-auto p-2 sm:h-auto")}*/}
            {/*        src={item?.img ?? ""}*/}
            {/*        alt={item?.title ?? dataDefault?.title ?? ""}*/}
            {/*        width="85"*/}
            {/*        height="77"*/}
            {/*        placeholder="blur"*/}
            {/*        blurDataURL="/images/blur.png"*/}
            {/*      />*/}
            {/*      <p className={classNames("text-center text-xs-custom font-light text-brand-text-white")}>*/}
            {/*        <Trans i18nKey={item?.title ?? dataDefault?.description ?? ""} />*/}
            {/*      </p>*/}
            {/*    </div>*/}
            {/*  ))}*/}
            {/*</div>*/}
          </div>
        </div>
      )}
    </div>
  );
};
