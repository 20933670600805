import { Schedule } from "@/components/ui-components/Schedule";
import { formatPhoneNumber } from "@/lib/helpers";
import { Provider } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { TbPhoneCall } from "react-icons/tb";

export const ProviderCard = ({
  className,
  provider,
  index,
  distance,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  provider: Provider;
  index: number;
  distance: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {}, [ref]);
  const toggleDropdown = () => setIsOpen(!isOpen);
  // const toggleDropdown = () => (isOpen ? removeEventListener() : addEventListener());
  //
  // const addEventListener = () => {
  //   setIsOpen(true);
  //   window.addEventListener("mousedown", (event: MouseEvent) => {
  //     if (ref.current && !ref.current.contains(event.target as Node)) {
  //       removeEventListener();
  //     }
  //   });
  // };
  //
  // const removeEventListener = () => {
  //   setIsOpen(false);
  //   window.removeEventListener("mousedown", () => {});
  // };

  return (
    <div
      key={index}
      className={classNames(
        "provider-card relative mb-4 rounded-2xl bg-brand-bg-light p-4 sm:p-8",
        className ?? ""
      )}
      {...rest}
      id={`scroll-${index}`}
      ref={ref}>
      <div className="mb-5 flex cursor-pointer items-center justify-between sm:mb-6" onClick={toggleDropdown}>
        <div className="flex items-center justify-start">
          <div className="mr-4 flex h-10 w-10 items-center justify-center rounded-full bg-brand-blue-100 sm:h-14 sm:w-14">
            <h3 className="text-[22px] font-semibold text-brand-text-blue-light-2 sm:text-2xl">{`${provider.First_Name.substring(0, 1).toUpperCase()}${provider.Last_Name.substring(0, 1).toUpperCase()}`}</h3>
          </div>
          <div className="flex flex-col">
            <h3 className="text-base font-semibold sm:text-2xl">{`${provider.First_Name} ${provider.Last_Name}`}</h3>
            <p className="text-xs font-light">{provider.Speciality.join(", ")}</p>
          </div>
        </div>
        <div className="flex items-center">
          {distance && distance != "" && (
            <p className="rounded-full bg-brand-bg-section-blue px-4 py-2 text-xs font-light">
              {distance?.replace("mi", "") + " "}
              <Trans i18nKey="miles_away" defaults="miles away" />
            </p>
          )}
          <IoIosArrowDown className={classNames("transition-all duration-300", isOpen ? "rotate-180" : "")} />
        </div>
      </div>
      <div className="flex flex-wrap">
        {provider.Accepting_New_Patients === "Y" && (
          <div className="mb-2 mr-2 flex items-center rounded-[20px] bg-brand-bg-section-green p-2 sm:mb-4">
            <FaCheckCircle className="mr-1 text-lg text-brand-text-green" />
            <p className="text-xs font-light text-brand-text-green">
              <Trans i18nKey="map_accepting_new_patients" />
            </p>
          </div>
        )}
        {provider.Network_ID != "" && (
          <div className="mb-2 mr-2 flex items-center rounded-[20px] bg-brand-bg-section-green p-2 sm:mb-4">
            <FaCheckCircle className="mr-1 text-lg text-brand-text-green" />
            <p className="text-xs font-light text-brand-text-green">
              <Trans i18nKey="map_in_network" />
            </p>
          </div>
        )}
        {provider.Board_Certified.length > 0 && (
          <div className="mb-2 mr-2 flex items-center rounded-[20px] bg-brand-bg-section-green p-2 sm:mb-4">
            <FaCheckCircle className="mr-1 text-lg text-brand-text-green" />
            <p className="text-xs font-light text-brand-text-green">
              <Trans i18nKey="map_board_certified" />
            </p>
          </div>
        )}
      </div>
      <div
        className={classNames(
          "grid overflow-hidden transition-all duration-300 ease-in-out",
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        )}>
        <div className="overflow-hidden border-t-[0.5px] border-brand-gray-50">
          <div className="mb-3 flex flex-wrap gap-4 border-b-[0.5px] border-brand-gray-50 py-4 sm:justify-between">
            <div className="flex flex-col">
              <p className="text-xs font-semibold text-brand-text-main">
                <Trans i18nKey="map_gender" />
              </p>
              {provider.Gender == "M" && (
                <p className="text-xs font-light text-brand-text-main">
                  <Trans i18nKey="map_male" />
                </p>
              )}
              {provider.Gender == "F" && (
                <p className="text-xs font-light text-brand-text-main">
                  <Trans i18nKey="map_female" />
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <p className="text-xs font-semibold text-brand-text-main">
                <Trans i18nKey="map_languages" />
              </p>
              {provider.Language_Code.map((lang, index) =>
                lang.Language_Code_name != "" ? (
                  <p key={index} className="text-xs font-light text-brand-text-main">
                    {index > 0 ? ", " : null}
                    <Trans i18nKey={lang.Language_Code_name} />
                  </p>
                ) : null
              )}
            </div>
            <div className="flex flex-col">
              <p className="text-xs font-semibold text-brand-text-main">
                <Trans i18nKey="specialty" />
              </p>
              <p className="text-xs font-light text-brand-text-main">{provider.Speciality.join(", ")}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs font-semibold text-brand-text-main">
                <Trans i18nKey="map_method_of_tele_health" />
              </p>
              <p className="text-xs font-light text-brand-text-main">{provider["Method of Telehealth"]}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="mb-4 text-base font-semibold text-brand-text-main sm:text-lg">
              <Trans i18nKey="map_office" />
            </p>
            <div className="flex">
              <div className="mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-brand-yellow-400 p-1.5">
                <FiMapPin className="text-base text-brand-text-white" />
              </div>
              <div className="flex flex-col">
                <p className="text-sm font-light text-brand-text-blue sm:text-base">
                  {provider.Address.Address_1}
                </p>
                <p className="text-sm font-light text-brand-text-blue sm:text-base">{`${provider.Address.City}, ${provider.Address.State}  ${provider.Address.Zip}`}</p>
              </div>
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <div className="mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-brand-green-550 p-1.5">
              <TbPhoneCall className="text-base text-brand-text-white" />
            </div>
            <Link
              className={classNames("font-light] text-sm sm:text-base")}
              href={"tel:" + provider.Phone_Number}>
              {formatPhoneNumber(provider.Phone_Number)}
            </Link>
          </div>
          <Schedule officeHours={provider.office_hours} />
        </div>
      </div>
    </div>
  );
};
