import { Client, ClientService, ListItem6 } from "@/lib/types";

const processUrl = (url: any): string => {
  return url;
  // if (typeof url !== "string") {
  //   return url;
  // }
  // if (url.startsWith("/images/")) {
  //   return url.replace("/images/", "/go/images/");
  // } else {
  //   return url;
  // }
};

export function processClientService(service: ClientService): ClientService {
  if (service.slide.img) {
    service.slide.img = processUrl(service.slide.img);
  }
  const sections = service.sections;
  if (sections) {
    if (sections.mainInfo?.mainImg) {
      sections.mainInfo.mainImg = processUrl(sections.mainInfo.mainImg);
    }
    if (sections.mainInfo?.logo) {
      sections.mainInfo.logo = processUrl(sections.mainInfo.logo);
    }
    if (sections.behavioralHealthBenefits?.img) {
      sections.behavioralHealthBenefits.img = processUrl(sections.behavioralHealthBenefits.img);
    }
    if (sections.behavioralHealthBenefits?.items) {
      sections.behavioralHealthBenefits.items.forEach((item) => {
        if (item.icon) {
          item.icon = processUrl(item.icon);
        }
      });
    }
    if (sections.usingYourBehavioralHealthBenefits?.img) {
      sections.usingYourBehavioralHealthBenefits.img = processUrl(
        sections.usingYourBehavioralHealthBenefits.img
      );
    }
    if (sections.behavioralHealthForms?.items) {
      sections.behavioralHealthForms.items.forEach((item) => {
        if (item.icon) {
          item.icon = processUrl(item.icon);
        }
      });
    }
    if (sections.cardsBlock?.cards) {
      sections.cardsBlock.cards.forEach((card) => {
        if (card.img) {
          card.img = processUrl(card.img);
        }
      });
    }

    if (sections.quicklyEliminateBackAndJointPain) {
      sections.quicklyEliminateBackAndJointPain.img2 = processUrl(
        sections.quicklyEliminateBackAndJointPain.img2
      );
      sections.quicklyEliminateBackAndJointPain.img1 = processUrl(
        sections.quicklyEliminateBackAndJointPain.img1
      );
      sections.quicklyEliminateBackAndJointPain.appStoreImg = processUrl(
        sections.quicklyEliminateBackAndJointPain.appStoreImg
      );
      sections.quicklyEliminateBackAndJointPain.googlePlayImg = processUrl(
        sections.quicklyEliminateBackAndJointPain.googlePlayImg
      );
      sections.quicklyEliminateBackAndJointPain.amazonAppstoreImg = processUrl(
        sections.quicklyEliminateBackAndJointPain.amazonAppstoreImg
      );
      if (sections.ourProgramsInclude?.items) {
        sections.ourProgramsInclude.items.forEach((i) => {
          i.img = processUrl(i.img);
        });
      }
      if (sections.cardsBlock) {
        sections.cardsBlock.cards.forEach((c) => {
          c.img = processUrl(c.img);
        });
      }
      if (sections.everythingYouNeedIsSentToYou) {
        sections.everythingYouNeedIsSentToYou.img = processUrl(sections.everythingYouNeedIsSentToYou.img);
        sections.everythingYouNeedIsSentToYou.items.forEach((c) => {
          c.img = processUrl(c.img);
        });
      }
      if (sections.checkYourSymptoms?.img) {
        sections.checkYourSymptoms.img = processUrl(sections.checkYourSymptoms?.img);
      }
      if (sections.healthCoachesGuide) {
        sections.healthCoachesGuide.img = processUrl(sections.healthCoachesGuide.img);
      }
    }
  }

  return service;
}

export function processClient(client: Client): Client {
  client.logo = processUrl(client.logo);
  client.img = processUrl(client.img);
  if (client.footer) {
    Object.keys(client.footer).forEach((key: string) => {
      if (key.startsWith("logo")) {
        client.footer[key as keyof Client["footer"]] = processUrl(
          client.footer[key as keyof Client["footer"]]
        );
      }
    });
  }
  client.services.forEach((service) => (service = processClientService(service)));

  return client;
}

export const initServiceData = (
  client: Client,
  clientDefault: Client,
  currentService: ClientService,
  defaultService: ClientService,
  tabSlug: string
): [ClientService, ClientService] => {
  let tabItems: ListItem6[] = [];
  let tabItemsDefault: ListItem6[] = [];
  let activeTab = 1;
  const templateService: ClientService = client.services.find(
    (p) => p.slug === "simplemsk-in-person" && p.slide.slug == "chiropractic"
  ) as ClientService;
  const templateServiceDefault: ClientService = clientDefault.services.find(
    (p) => p.slug === "simplemsk-in-person" && p.slide.slug == "chiropractic"
  ) as ClientService;

  client.services.forEach((item) => {
    if (
      currentService &&
      currentService.sections &&
      defaultService &&
      defaultService.sections &&
      item.isShow
    ) {
      let index = -1;
      switch (item.slide.slug) {
        case "chiropractic":
          index = 0;
          break;
        case "acupuncture":
          index = 1;
          break;
        case "physical-therapy":
          index = 2;
          break;
        case "occupational-therapy":
          index = 3;
          break;
        case "speech-therapy":
          index = 4;
          break;
      }
      if (index != -1) {
        tabItems.push(templateService.sections?.tabs?.items[index] as ListItem6);
        tabItemsDefault.push(templateServiceDefault.sections?.tabs?.items[index] as ListItem6);
        tabSlug == item.slide.slug ? (activeTab = tabItems.length) : null;
      }
    }
  });
  tabItems.push(templateService.sections?.tabs?.items[5] as ListItem6);
  tabItemsDefault.push(templateServiceDefault?.sections?.tabs?.items[5] as ListItem6);

  if (templateService.sections) {
    currentService.sections = templateService.sections;
    if (currentService.sections.tabs) {
      currentService.sections.tabs.items = tabItems;
      currentService.sections.tabs.activeTab = activeTab;
    }
  }

  if (templateServiceDefault && templateServiceDefault.sections) {
    defaultService.sections = templateServiceDefault.sections;
    if (defaultService.sections.tabs) {
      defaultService.sections.tabs.items = tabItemsDefault;
    }
  }
  return [currentService, defaultService];
};

export const getLinkWithClientID = (url: string, clientId: any) => {
  const toReplace = "{{client_config_id}}";
  if (url && typeof url === "string" && url.includes(toReplace) && clientId && typeof clientId === "string") {
    return url.replace(toReplace, clientId);
  } else {
    return url;
  }
};

export const isLocalhost = () => {
  if (typeof window === "undefined") return false;
  return window.location.hostname === "localhost";
};

export function checkImageSrc(src: string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      console.warn("!Warning, image src not valid:", src);
      resolve(false);
    };
    img.src = src;
  });
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};
