import AppFooter from "@/components/AppFooter";
import { AppHeader } from "@/components/AppHeader";
import { Services } from "@/components/service-sections/Services";
import { Footer, ClientService } from "@/lib/types";
import classNames from "classnames";
import React from "react";

export function SingleServiceScreen({
  className,
  service,
  serviceDefault,
  footer,
  logo,
  clientSlug,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  footer: Footer | undefined;
  service?: ClientService | undefined;
  serviceDefault?: ClientService | undefined;
  logo: string | undefined;
  clientSlug: string | undefined;
}) {
  return (
    <div className={classNames("flex min-h-screen flex-col items-center", className ?? "")} {...rest}>
      <AppHeader logo={logo} slug={clientSlug} />
      <Services service={service} serviceDefault={serviceDefault} clientSlug={clientSlug} />
      <AppFooter footer={footer} />
    </div>
  );
}
