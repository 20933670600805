import useAppContext from "@/lib/hooks/useAppContext";
import { Language } from "@/lib/types";
import classNames from "classnames";
import { getCookie } from "cookies-next";
import React, { useEffect, useState } from "react";

const getImageSize = (size: "md" | "lg") => {
  switch (size) {
    case "md":
      return "w-6 h-6";
    case "lg":
      return "w-10 h-10";
    default:
      return "w-6 h-6";
  }
};

function LanguageSelector({
  className,
  hideLabel,
  size = "md",
  shortAbbreviation,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  hideLabel?: boolean;
  size?: "md" | "lg";
  shortAbbreviation?: boolean;
}) {
  const { changeAppLanguage } = useAppContext();
  const { languages } = useAppContext();
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);

  useEffect(() => {
    if (languages) {
      const currentLocale = getCookie("NEXT_LOCALE") || "en";
      const current = languages.find((lang: Language) => lang.abbreviation == currentLocale);
      if (current) {
        setCurrentLanguage(current);
      }
    }
  }, [languages, currentLanguage]);

  const changeLanguage = async (lang: Language) => {
    setCurrentLanguage(lang);
    changeAppLanguage(lang.abbreviation);
  };
  return (
    <div className={classNames("flex items-center justify-center bg-transparent", className)} {...rest}>
      <div className="group relative z-20 cursor-pointer">
        {currentLanguage && (
          <div className="flex items-center justify-between sm:px-4">
            <div className="menu-hover flex items-center justify-start ">
              <img
                className={classNames("sm:mr-2", getImageSize(size))}
                src={currentLanguage.flag}
                alt={currentLanguage.original_language}
              />
              {!hideLabel && (
                <span className="hidden text-[16px] font-light sm:flex">
                  {shortAbbreviation
                    ? currentLanguage.abbreviation.toUpperCase()
                    : currentLanguage.original_language}
                </span>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames(
            "absolute right-0 grid w-full min-w-40 overflow-hidden rounded-lg !bg-white shadow-xl group-hover:pt-2 sm:right-auto",
            "grid-rows-[0fr] transition-all duration-300 group-hover:grid-rows-[1fr]",
            size === "md" ? "top-8" : "top-12"
          )}>
          <ul className="m-0 min-h-0 p-0">
            {languages
              .filter((l) => l.abbreviation != currentLanguage?.abbreviation)
              .map((lang) => (
                <li
                  key={lang.abbreviation}
                  className={classNames(
                    "mb-2 flex items-center justify-start py-1 pl-4 text-[16px] font-light hover:bg-gray-100 hover:text-black"
                  )}
                  onClick={() => changeLanguage(lang)}>
                  <img
                    className={classNames("mr-2", getImageSize(size))}
                    src={lang.flag}
                    alt={lang.abbreviation}
                  />
                  {lang.original_language}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LanguageSelector;
