import classNames from "classnames";
import { Trans } from "next-i18next";
import React from "react";

export const BlockHeader = ({
  className,
  title,
  description,
  classTextColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  description: string;
  classTextColor: string | undefined;
}) => {
  return (
    <div className={classNames("mb-0", className ?? "")} {...rest}>
      <h2
        className={classNames(
          "w-full py-4 text-left text-2xl-custom font-medium sm:text-5xl",
          `${classTextColor}`
        )}>
        <Trans i18nKey={title} />
      </h2>
      {description != "" && (
        <p className="mx-auto mb-6 text-base text-brand-text-blue sm:mb-0 sm:mt-4 sm:text-2xl sm:font-semibold">
          <Trans i18nKey={description} />
        </p>
      )}
    </div>
  );
};
