import { CheckOutOurMemberVideo } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React from "react";
import ReactPlayer from "react-player/lazy";

export const SectionCheckOutOurMemberVideo = ({
  className,
  data,
  dataDefault,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: CheckOutOurMemberVideo | undefined;
  dataDefault?: CheckOutOurMemberVideo | undefined;
}) => {
  return (
    <div className={classNames("", className ?? " bg-brand-bg-light")} {...rest}>
      {data && (
        <div className="container pb-8 pt-8 md:pb-16 md:pt-[56px]">
          <h1
            className={classNames(
              "w-full max-w-[328px] rounded-2xl py-4 text-[28px] font-medium sm:max-w-none sm:text-center sm:text-5xl"
            )}>
            <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
          </h1>
          <ReactPlayer
            className="check-out-our-member-video mx-auto aspect-video w-10/12 self-stretch md:min-h-96"
            width="100%"
            height="100%"
            controls
            playing
            light="/images/simplemsk-virtual/video-preview.png"
            playIcon={
              <Image
                className="order-0 object-contain"
                src="/images/simplemsk-virtual/video-play.png"
                width="94"
                height="100"
                alt="Play"
              />
            }
            url={data?.videoUrl ?? ""}
          />
        </div>
      )}
    </div>
  );
};
