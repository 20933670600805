import { QuicklyEliminateBackAndJointPain } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export const SectionQuicklyEliminateBackAndJointPain = ({
  className,
  data,
  dataDefault,
  classBgColor,
  classMainColor,
  classBorderColor,
  classTextColor,
  classGroupHoverColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: QuicklyEliminateBackAndJointPain | undefined;
  dataDefault?: QuicklyEliminateBackAndJointPain | undefined;
  slug?: string | undefined;
  classBgColor: string | undefined;
  classMainColor: string | undefined;
  classBorderColor: string | undefined;
  classTextColor: string | undefined;
  classGroupHoverColor: string | undefined;
}) => {
  return (
    <div className={classNames(classBgColor, className ?? " pb-6 sm:pb-0")} {...rest}>
      {data && (
        <div className="container sm:p-10">
          <div className="grid gap-6 sm:gap-14 lg:grid-cols-2">
            <div className="relative order-2 lg:order-1 lg:col-span-1">
              <Image
                className="h-full w-full"
                src={data.img1}
                width={659}
                height={507}
                alt={data?.title ?? dataDefault?.title ?? ""}
                placeholder="blur"
                blurDataURL="/images/r.png"
              />
              <div className="absolute bottom-8 right-8 flex flex-col justify-center rounded-[10px] bg-brand-bg-default p-4 shadow-lg ">
                <p className="mb-2 text-center text-sm font-semibold">
                  <Trans i18nKey={data.img1Text ?? dataDefault?.img1Text ?? ""} />
                </p>
                <Link
                  className="mx-auto mr-4 block w-full rounded-full border border-brand-blue-500 text-center text-xs font-semibold text-brand-text-blue-light"
                  href={`${data.getStartedLink}`}
                  target="_blank">
                  <Trans i18nKey="get_started" />
                </Link>
              </div>
            </div>
            <div className="order-1 flex flex-col justify-center md:col-span-1 lg:order-2 lg:col-span-1">
              <h2
                className={classNames(
                  "py-4l w-full text-[28px] font-medium leading-normal sm:max-w-[550px] sm:text-5xl-custom",
                  classTextColor
                )}>
                <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
              </h2>
              <p className="mt-2 text-left text-lg font-light sm:mt-6">
                <Trans i18nKey={data.description ?? dataDefault?.description ?? ""} />
              </p>
              <div className="mt-3 flex flex-wrap items-center justify-center gap-4 sm:mb-12 sm:mt-8 sm:justify-start sm:gap-6 ">
                <Link className="" href={`${data.appStoreLink}`} target="_blank">
                  <Image
                    className="h-14 w-40"
                    src={data?.appStoreImg ?? dataDefault?.appStoreImg ?? ""}
                    width="153"
                    height="51"
                    alt="App Store"
                    placeholder="blur"
                    blurDataURL="/images/blur.png"
                  />
                </Link>
                <Link className="" href={`${data.googlePlayLink}`} target="_blank">
                  <Image
                    className="h-14 w-40"
                    src={data?.googlePlayImg ?? dataDefault?.googlePlayImg ?? ""}
                    width="172"
                    height="51"
                    alt="Google Play"
                  />
                </Link>
                <Link className="" href={`${data.amazonAppstoreLink}`} target="_blank">
                  <Image
                    className="h-14 w-40"
                    width="174"
                    height="51"
                    src={data?.amazonAppstoreImg ?? dataDefault?.amazonAppstoreImg ?? ""}
                    alt="Amazon appstore"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-start lg:flex-row lg:justify-between">
            <div className="order-2 mb-6 rounded-2xl bg-brand-bg-light p-8 sm:mb-0 sm:mt-10 lg:order-1 lg:mr-6 lg:mt-0 lg:w-2/3">
              <h3 className={classNames("mb-8 text-left text-3xl-custom font-semibold")}>
                <Trans i18nKey={data?.itemsTitle ?? dataDefault?.itemsTitle ?? ""} />
              </h3>
              <div className="flex flex-col">
                {(data?.items ?? dataDefault?.items)?.map((item, index) => (
                  <div key={index} className="group mb-9 flex flex-row items-center">
                    <p
                      className={classNames(
                        "mr-2 rounded-full border px-4 py-1.5 text-2xl font-light group-hover:text-brand-text-white",
                        classTextColor,
                        classGroupHoverColor,
                        classBorderColor
                      )}>
                      {index + 1}
                    </p>
                    <div key={index} className="">
                      <p className="text-xl font-light group-hover:font-semibold">
                        <Trans i18nKey={item?.title ?? ""} />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classNames("order-1 rounded-[2.5rem] py-10 lg:order-2 lg:pl-10", classMainColor)}>
              <Image
                className="col-span-2 h-full w-full md:col-span-1"
                src={data.img2}
                width={478}
                height={383}
                alt={data?.title ?? dataDefault?.title ?? ""}
                placeholder="blur"
                blurDataURL="/images/blur.png"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
