import { AppHeader } from "@/components/AppHeader";
import useAppContext from "@/lib/hooks/useAppContext";
import classNames from "classnames";
import Link from "next/link";
import React from "react";

export function HomeScreen({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  const { clientsList } = useAppContext();
  return (
    <div className={classNames("min-h-screen", className ?? "")} {...rest}>
      <AppHeader logo="" slug="" />
      <div className="p-20">
        <h2>Our clients</h2>
        <ul>
          {clientsList?.map((client) => (
            <li key={client.id}>
              <Link href={client.url_friendly_name ?? ""} className="text-blue-500 hover:underline">
                {client.name}
                <span className="ml-2">(slug: {client.url_friendly_name})</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
