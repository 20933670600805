import { OfficeHours } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import React, { useState, useEffect, useRef } from "react";
import { FaRegClock } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";

export const Schedule = ({
  className,
  officeHours,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  officeHours: OfficeHours;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {}, [ref]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  // const toggleDropdown = () => (isOpen ? removeEventListener() : addEventListener());
  //
  // const addEventListener = () => {
  //   setIsOpen(true);
  //   window.addEventListener("mousedown", (event: MouseEvent) => {
  //     if (ref.current && !ref.current.contains(event.target as Node)) {
  //       removeEventListener();
  //     }
  //   });
  // };
  //
  // const removeEventListener = () => {
  //   setIsOpen(false);
  //   window.removeEventListener("mousedown", () => {});
  // };
  const areAllDaysNotAvailable = (schedule: OfficeHours): boolean => {
    return Object.values(schedule).every((day) => day.start_time === "NOT AVAILABLE");
  };

  return (
    <div
      className={classNames("rounded-[10px] bg-brand-bg-section-blue p-4", className ?? "")}
      {...rest}
      ref={ref}>
      <div className="flex cursor-pointer justify-between" onClick={toggleDropdown}>
        <div className="flex items-center">
          <FaRegClock className="mr-4 text-brand-text-blue-light" />
          <p className="text-sm font-semibold text-brand-text-blue sm:text-base">
            <Trans i18nKey="map_available_times" />
          </p>
        </div>
        <IoIosArrowDown className={classNames("transition-all duration-300", isOpen ? "rotate-180" : "")} />
      </div>

      <div
        className={classNames(
          "grid overflow-hidden transition-all duration-300 ease-in-out",
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        )}>
        <div className={classNames("overflow-hidden")}>
          {areAllDaysNotAvailable(officeHours) ? (
            <p className="mr-1 mt-1 pl-10 text-xs font-light text-brand-text-main">
              <Trans i18nKey="office_hrs_not_reported" />
            </p>
          ) : (
            <ul>
              {Object.keys(officeHours).map((day: string, index) => (
                <li key={index} className="mt-1 flex justify-start pl-10">
                  <p className="mr-1 text-xs font-light text-brand-text-main">{day}:</p>
                  <p className="text-xs font-light text-brand-text-main">
                    {Object.values(officeHours)[index].start_time}
                    {Object.values(officeHours)[index].end_time != "" ? " - " : ""}
                    {Object.values(officeHours)[index].end_time}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
