import { FileLink } from "@/components/ui-components/FileLink";
import { UsingYourBehavioralHealthBenefits } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React from "react";

export const SectionUsingYourBehavioralHealthBenefits = ({
  className,
  data,
  slug,
  dataDefault,
  classMainColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: UsingYourBehavioralHealthBenefits | undefined;
  dataDefault?: UsingYourBehavioralHealthBenefits | undefined;
  classMainColor: string | undefined;
  slug?: string | undefined;
}) => {
  return (
    <div className={classNames("", className ?? "", `${classMainColor}`)} {...rest}>
      {data && (
        <div className="container grid grid-cols-9 gap-4 py-10">
          <div className="col-span-9 sm:pt-8 md:col-span-5">
            <h2
              className={classNames(
                "py-4l w-full text-[28px] font-medium text-brand-text-white sm:text-5xl"
              )}>
              <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
            </h2>
            <p className="mt-5 text-left text-lg font-light text-brand-text-white sm:mt-10">
              <Trans i18nKey={data?.description1 ?? dataDefault?.description1 ?? ""} />
              <a className="pl-2 font-light  text-brand-text-white" href={"tel:" + data.phone}>
                {data.phone}
              </a>
            </p>
            <p className="mb-5 text-left text-lg font-light text-brand-text-white sm:mb-10">
              <Trans i18nKey={data?.description2 ?? dataDefault?.description2 ?? ""} />
            </p>
            {data.files.map((file, index) => (
              <FileLink
                key={index}
                slug={slug}
                classFillColor="fill-brand-text-white"
                className={classNames(`text-left text-xl font-semibold text-brand-text-white`)}
                file={file}
              />
            ))}
          </div>
          <div className="col-span-9 md:col-span-4">
            <Image
              className="h-full rounded-xl object-cover"
              src={data.img}
              alt={data?.title ?? dataDefault?.title ?? ""}
              width="664"
              height="440"
              placeholder="blur"
              blurDataURL="/images/blur.png"
            />
          </div>
        </div>
      )}
    </div>
  );
};
