const themeColors = {
  simpleeap: {
    borderColor20: "border-brand-blue-800/20",
    borderColor: "border-brand-blue-800",
    hoverBorder: "hover:border-brand-blue-300",
    hoverBg: "hover:bg-brand-blue-300",
    groupHoverBg: "group-hover:bg-brand-blue-800",
    groupHoverText: "group-hover:text-brand-blue-800",
    afterBg: "after:bg-brand-blue-800",
  },
  simplewellbeing: {
    borderColor20: "border-brand-green-900/20",
    borderColor: "border-brand-green-900",
    hoverBorder: "hover:border-brand-red-400",
    hoverBg: "hover:bg-brand-red-400",
    groupHoverBg: "group-hover:bg-brand-red-900",
    groupHoverText: "group-hover:text-brand-red-900",
    afterBg: "after:bg-brand-red-900",
  },
  simplebehavioral: {
    borderColor20: "border-brand-blue-600/20",
    borderColor: "border-brand-blue-600",
    hoverBorder: "hover:border-brand-blue-300",
    hoverBg: "hover:bg-brand-green-300",
    groupHoverBg: "group-hover:bg-brand-green-600",
    groupHoverText: "group-hover:text-brand-green-600",
    afterBg: "after:bg-brand-green-600",
  },
  "simplemsk-virtual": {
    borderColor20: "border-brand-orange-600/20",
    borderColor: "border-brand-orange-600",
    hoverBorder: "hover:border-brand-orange-300",
    hoverBg: "hover:bg-brand-orange-300",
    groupHoverBg: "group-hover:bg-brand-orange-600",
    groupHoverText: "group-hover:text-brand-orange-600",
    afterBg: "after:bg-brand-orange-600",
  },
  "simplemsk-in-person": {
    borderColor20: "border-brand-orange-600/20",
    borderColor: "border-brand-orange-600",
    hoverBorder: "hover:border-brand-orange-300",
    hoverBg: "hover:bg-brand-orange-300",
    groupHoverBg: "group-hover:bg-brand-orange-600",
    groupHoverText: "group-hover:text-brand-orange-600",
    afterBg: "after:bg-brand-orange-600",
  },
};

export default themeColors;
