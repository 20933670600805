import { HealthCoachesGuide } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import { ImMobile2 } from "react-icons/im";

export const SectionHealthCoachesGuide = ({
  className,
  data,
  dataDefault,
  classMainColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: HealthCoachesGuide | undefined;
  dataDefault?: HealthCoachesGuide | undefined;
  classMainColor: string;
}) => {
  const imageSrc = data?.img;

  return (
    <div className={classNames("w-full", className ?? "", `${classMainColor}`)} {...rest}>
      {data && (
        <div className="lg:container lg:mx-auto">
          <div className="grid grid-cols-9 p-10 lg:gap-16">
            <div className="col-span-9 lg:col-span-4">
              <Image
                className="mx-auto h-full w-full rounded-xl object-cover"
                src={imageSrc as string}
                alt={data?.title ?? dataDefault?.title ?? ""}
                width="516"
                height="456"
                placeholder="blur"
                blurDataURL="/images/blur.png"
              />
            </div>
            <div className="col-span-9 sm:mt-10 lg:col-span-5 lg:mt-0">
              <h2
                className={classNames(
                  "w-full rounded-2xl py-4 text-[28px] font-medium text-white sm:text-5xl"
                )}>
                <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
              </h2>
              <p className="text-left text-base font-light text-white sm:mt-6 sm:w-9/12 sm:text-lg">
                <Trans i18nKey={data?.description ?? dataDefault?.description ?? ""} />
              </p>
              <p className="relative mt-4 flex flex-row items-center gap-4 text-base font-light text-white sm:mt-10 sm:text-lg">
                <div className="flex h-[42px] w-[42px] flex-shrink-0 items-center justify-center rounded-full  bg-white">
                  <FaRegHeart className={classNames("text-[26px] text-brand-red-900")} />
                </div>
                <span>
                  <Trans i18nKey={data.point1Title ?? dataDefault?.point1Title ?? ""} />
                </span>
              </p>
              <p className="relative mt-4 flex flex-row items-center gap-4 text-base font-light text-white sm:mt-10 sm:text-lg">
                <div className="flex h-[42px] w-[42px] flex-shrink-0 items-center justify-center rounded-full  bg-white">
                  <ImMobile2 className={classNames("text-[26px] text-brand-red-900")} />
                </div>
                <span>
                  <Trans i18nKey={data.point1Title ?? dataDefault?.point2Title ?? ""} />
                </span>
              </p>
              <p className="relative mt-4 flex flex-row items-center gap-4 text-base font-light text-white sm:mt-10 sm:text-lg">
                <div className="flex h-[42px] w-[42px] flex-shrink-0 items-center justify-center rounded-full  bg-white">
                  <FiGlobe className={classNames("text-[26px] text-brand-red-900")} />
                </div>
                <span>
                  <Trans i18nKey={data.point1Title ?? dataDefault?.point3Title ?? ""} />
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
