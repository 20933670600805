import classNames from "classnames";
import React from "react";
import { IoArrowForwardOutline } from "react-icons/io5";

export const Pagination = ({
  className,
  totalPages,
  currentPage,
  getPage,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  totalPages: number;
  currentPage: number;
  getPage: any;
}) => {
  const generatePageNumbers = () => {
    const pages = [];
    const isSmallScreen = window.innerWidth < 640;
    const sidePages = isSmallScreen ? 1 : 2;

    const startPage = Math.max(2, currentPage - sidePages);
    const endPage = Math.min(totalPages - 1, currentPage + sidePages);

    // Add the first few pages
    for (let i = 1; i <= Math.min(isSmallScreen ? 2 : 3, startPage - 1); i++) {
      pages.push(i);
    }

    if (startPage > (isSmallScreen ? 3 : 4)) {
      pages.push("...");
    }

    // Add the middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages - (isSmallScreen ? 2 : 3)) {
      pages.push("...");
    }

    // Add the last few pages
    for (let i = Math.max(totalPages - (isSmallScreen ? 1 : 2), endPage + 1); i <= totalPages; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pages = generatePageNumbers();

  const changeCurrentPage = (page: number) => {
    if (currentPage != page && page >= 1 && page <= totalPages) {
      getPage(page);
    }
  };

  return (
    <div className={classNames("", className ?? "")} {...rest}>
      {totalPages > 1 && currentPage > 0 && (
        <div className="flex flex-wrap items-center justify-center">
          <button
            className={classNames(
              "group mr-1.5 flex h-10 w-10 rotate-180 items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300",
              currentPage != 1 ? "hover:bg-brand-blue-900 hover:text-white" : ""
            )}
            onClick={() => changeCurrentPage(currentPage - 1)}>
            <IoArrowForwardOutline />
          </button>
          {pages.map((page, index) =>
            page === "..." ? (
              <span key={index} className="font-semibol px-2 py-2 text-lg sm:px-3">
                {page}
              </span>
            ) : (
              <button
                key={index}
                className={classNames(
                  "mx-1.5 px-[7px] py-[17px] text-base font-semibold transition-colors duration-300 sm:px-[9px] sm:text-lg",
                  currentPage === page ? "cursor-none underline" : "hover:text-brand-text-blue-light"
                )}
                onClick={() => changeCurrentPage(page as number)}>
                {page}
              </button>
            )
          )}
          <button
            className={classNames(
              "group ml-1.5 flex h-[38px] w-[41px] items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300",
              currentPage != totalPages ? "hover:bg-brand-blue-900 hover:text-white" : ""
            )}
            onClick={() => changeCurrentPage(currentPage + 1)}>
            <IoArrowForwardOutline />
          </button>
        </div>
      )}
    </div>
  );
};
