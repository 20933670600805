import { OurProgramsInclude } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React, { useRef, useState } from "react";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const SectionOurProgramsInclude = ({
  className,
  data,
  slug,
  dataDefault,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: OurProgramsInclude | undefined;
  dataDefault?: OurProgramsInclude | undefined;
  slug?: string | undefined;
}) => {
  const swiperRef = useRef<SwiperType>();
  const [activeSlide, setActiveSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  return (
    <div className={classNames("", className ?? " mb-8 md:mb-12 ")} {...rest}>
      {data && (
        <div className=" mt-3 pl-4 sm:mt-10">
          <h1
            className={classNames(
              "mb-5 w-full rounded-2xl py-4 text-center text-[28px] font-medium sm:mb-0 sm:text-5xl"
            )}>
            <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
          </h1>
          <div
            className={`hidden px-5 sm:block lg:px-0 ${activeSlide > 0 ? "xl:mr-[calc(50%-720px)]" : ""} ${activeSlide === 0 && "xl:ml-[calc(50%-720px)]"} transition-all duration-300`}>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={4}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 32,
                },
              }}
              pagination={{
                clickable: true,
                el: ".paginate",
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              onInit={(swiper) => {
                setTotalSlides(swiper.slides.length);
              }}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.realIndex);
              }}
              className={classNames(className ?? "", "!sm:pb-16 !pb-6")}>
              {data?.items?.map((item, index) => (
                <SwiperSlide key={index} className="group !h-auto">
                  <div className="flex h-full shrink flex-col items-start justify-center p-2">
                    <Image
                      className="h-72 max-h-72 min-h-72 w-full rounded-lg object-cover transition-all duration-300 group-hover:h-64 group-hover:min-h-64 sm:mb-4"
                      src={item.img}
                      alt="slide"
                      width="330"
                      height="257"
                      placeholder="blur"
                      blurDataURL="/images/blur.png"
                    />
                    <div
                      className={classNames(
                        "transition-color flex h-full w-full shrink flex-col items-start justify-start rounded-xl pl-4 pt-4 duration-300",
                        themeColors[slug as keyof typeof themeColors]?.groupHoverText
                      )}>
                      <h3 className="mb-4 text-[20px] font-semibold sm:text-2xl">
                        <Trans i18nKey={item?.title ?? ""} />
                      </h3>
                      <h3 className="text-left text-base font-light">
                        <Trans i18nKey={item?.description ?? ""} />
                      </h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="container relative hidden items-center justify-between sm:flex">
            <div className="swiper-pagination paginate !relative flex" />
            <div className={`${totalSlides < 4 ? "hidden" : "flex"}`}>
              <button
                className="group mr-3 flex h-10 w-10 rotate-180 items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300 hover:bg-brand-blue-900 hover:text-white"
                onClick={() => swiperRef.current?.slidePrev()}>
                <IoArrowForwardOutline />
              </button>
              <button
                className="group flex h-10 w-10 items-center justify-center rounded-full border border-brand-blue-900 text-lg text-brand-blue-900 transition-colors duration-300 hover:bg-brand-blue-900 hover:text-white"
                onClick={() => swiperRef.current?.slideNext()}>
                <IoArrowForwardOutline />
              </button>
            </div>
          </div>
          <div className="container flex flex-col sm:hidden">
            {data?.items?.map((item, index) => (
              <div className="group flex h-full shrink flex-col items-start justify-center p-2" key={index}>
                <Image
                  className="h-72 max-h-72 min-h-72 w-full rounded-lg object-cover transition-all duration-300 group-hover:h-64 group-hover:min-h-64 sm:mb-4"
                  src={item.img}
                  alt="slide"
                  width="330"
                  height="257"
                  placeholder="blur"
                  blurDataURL="/images/blur.png"
                />
                <div
                  className={classNames(
                    "transition-color flex h-full w-full shrink flex-col items-start justify-start rounded-xl pl-4 pt-4 duration-300",
                    themeColors[slug as keyof typeof themeColors]?.groupHoverText
                  )}>
                  <h3 className="mb-4 text-[20px] font-semibold sm:text-2xl">
                    <Trans i18nKey={item?.title ?? ""} />
                  </h3>
                  <h3 className="text-left text-base font-light">
                    <Trans i18nKey={item?.description ?? ""} />
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
