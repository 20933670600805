import { Location } from "@/lib/types/location";
import { MapCoordinates } from "@/lib/types/mapCoordinates";

import DirectionsResult = google.maps.DirectionsResult;

export async function getCoordinates(addresses: string[]): Promise<MapCoordinates[]> {
  const coordinates: MapCoordinates[] = [];
  const geocoder = new window.google.maps.Geocoder();

  const promises = addresses?.map((address) => geocoder.geocode({ address: address }));
  const responses = await Promise.all(promises);
  responses.forEach((resp) => {
    const result = resp?.results[0];
    const { lat, lng } = result.geometry.location;
    coordinates.push({
      address: result.formatted_address,
      lat: lat(),
      lng: lng(),
      placeId: result.place_id,
    });
  });
  return coordinates;
}

export async function getCurrentLocationAddress(): Promise<string> {
  let address: string = "";
  if ("geolocation" in navigator) {
    try {
      const pos: Location = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = pos.coords;
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(latitude, longitude);
      await geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results?.length) {
          address = results ? results[0].formatted_address : "";
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    } catch (error) {
      console.error("Error getting current location or geocoding:", error);
    }
  } else {
    console.error("Geolocation is not available in this browser.");
  }
  return address;
}

export async function getDistance(currentLocationAddress: string, destination: string): Promise<string> {
  // currentLocationAddress = "75100 Mediterranean Ave Palm Desert, CA 92211";
  return await new Promise((resolve) => {
    try {
      // NOTE https://developers.google.com/maps/documentation/javascript/directions?hl=en
      const directionsService = new window.google.maps.DirectionsService();
      const request = {
        origin: currentLocationAddress,
        destination: destination,
        travelMode: google.maps.TravelMode["DRIVING"],
      };
      directionsService.route(request, function (result: DirectionsResult | null, status) {
        if (status == "OK" && result !== null) {
          // @ts-ignore
          const distance: string | undefined = result?.routes[0].legs[0].distance.text;
          resolve(distance);
        } else {
          resolve("");
        }
      });
    } catch (error) {
      //console.error("Error calculating distance:", error.message);
      resolve("");
    }
  });
}
