import { Spinner } from "@/components/ui-components/Spinner";
import { generatePDF } from "@/lib/pdfProvidersHelper";
import { SearchProvider } from "@/lib/types";
import classNames from "classnames";
import { Trans, useTranslation } from "next-i18next";
import Image from "next/image";
import React, { useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";

export const ExportProvidersToPDF = ({
  className,
  currentLocationAddress,
  searchProvider,
  totalProviders,
  phoneForQuestions,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  currentLocationAddress: string;
  searchProvider: SearchProvider;
  totalProviders: number;
  phoneForQuestions: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const toggleDropdown = () => (isOpen ? removeEventListener() : addEventListener());

  const addEventListener = () => {
    setIsOpen(true);
    window.addEventListener("mousedown", (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        removeEventListener();
      }
    });
  };

  const removeEventListener = () => {
    setIsOpen(false);
    window.removeEventListener("mousedown", () => {});
  };

  const exportPDF = async () => {
    setIsExporting(true);

    const translations = {
      title: t("pdf_provider_search_directory"),
      subtitle: t("pdf_providers_found"),
      listGeneratedOn: t("pdf_list_generated_on"),
      lastUpdate: t("pdf_providers_directory_last_update"),
      searchCriteriaFor: t("pdf_search_criteria_for"),
      firstName: t("form_search_for_a_provider_first_name"),
      lastName: t("form_search_for_a_provider_last_name"),
      clinic: t("form_search_for_a_provider_clinic"),
      category: t("pdf_category"),
      distance: t("pdf_distance"),
      miles: t("form_search_for_a_provider_select_miles"),
      zipCode: t("form_search_for_a_provider_zipcode"),
      aboutYourSearchResults: t("pdf_about_your_search_results"),
      thisListIsUpdatedDailyAndSubject: t("pdf_this_list_is_updated_daily_and_subject"),
      yourResultsContainsProvidersWithOfficeLocations: t(
        "pdf_your_results_contain_providers_with_office_locations"
      ),
      ifYouHaveAnyQuestionsAboutAProvider: t("pdf_if_you_have_any_questions_about_a provider"),
      termsOfUse: t("pdf_terms_of_use"),
      theListOfProvidersWasPrinted: t("pdf_the_list_of_providers_was_printed"),
      allRightsReserved: t("pdf_all_rights_reserved"),
      acceptingNewPatients: t("map_accepting_new_patients"),
      inNetwork: t("map_in_network"),
      boardCertified: t("map_board_certified"),
      languages: t("map_languages"),
      gender: t("map_gender"),
      female: t("map_female"),
      male: t("map_male"),
      specialty: t("specialty"),
      methodOfTeleHealth: t("map_method_of_tele_health"),
      office: t("map_office"),
      availableTimes: t("map_available_times"),
      milesAway: t("miles_away"),
      officeHrsNotReported: t("office_hrs_not_reported"),
    };

    let searchProvidersAll: SearchProvider = searchProvider;
    searchProvidersAll.limit = totalProviders;
    await generatePDF(searchProvidersAll, translations, phoneForQuestions, currentLocationAddress);

    setIsExporting(false);
  };

  return (
    <div className={classNames("relative", className ?? "")} {...rest} ref={ref} role="status">
      <div className="flex items-center">
        {isExporting && <Spinner className="mr-2" />}
        <button
          className="flex cursor-pointer items-center rounded-md border border-brand-gray-100 px-6 py-4"
          onClick={toggleDropdown}>
          <Image className="mr-1" src="/images/icons/upload.svg" width="11" height="13" alt="" />
          <span className="mr-1 text-sm font-medium sm:text-base">
            <Trans i18nKey="Export results" />
          </span>
          <IoIosArrowDown className={classNames("text-base font-medium", isOpen ? "rotate-180" : "")} />
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 top-16 z-10 cursor-pointer rounded-md bg-brand-bg-default px-7 py-5 shadow-md">
          <button className="flex items-center" onClick={exportPDF}>
            <IoMdDownload className="mr-0.5 text-lg" />
            <p className="text-base font-medium">
              <Trans i18nKey="Download PDF" />
            </p>
          </button>
        </div>
      )}
    </div>
  );
};
