import { BehavioralHealthBenefits } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import React from "react";

export const SectionBehavioralHealthBenefits = ({
  className,
  data,
  dataDefault,
  classBgColor,
  classTextColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: BehavioralHealthBenefits | undefined;
  dataDefault?: BehavioralHealthBenefits | undefined;
  classBgColor: string | undefined;
  classTextColor: string | undefined;
}) => {
  return (
    <div className={classNames("", className ?? "", classBgColor)} {...rest}>
      {data && (
        <div className={classNames("container py-7 sm:py-12")}>
          <div className="mb-4 grid grid-cols-9 gap-4 p-0 sm:mb-8 sm:p-10">
            <div className="col-span-9 sm:pr-10 md:col-span-5">
              <h2 className={classNames("py-4l w-full text-left text-5xl font-medium", `${classTextColor}`)}>
                <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
              </h2>
              <p className="mt-6 text-left text-base font-light sm:text-lg">
                <Trans i18nKey={data.description ?? dataDefault?.description ?? ""} />
              </p>
            </div>
            <div className="col-span-9 md:col-span-4">
              <img className="h-full min-h-[300px] rounded-xl object-cover" src={data.img} alt="" />
            </div>
          </div>
          <div className="rounded-2xl bg-brand-bg-default pb-2 pl-4 pt-8 sm:pb-10 sm:pt-[80px] md:pl-[60px]">
            <div className="max-w-[1164px]">
              {(data?.items ?? dataDefault?.items)?.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    " relative flex flex-row gap-6 rounded-2xl bg-brand-bg-default pl-4 pr-4  sm:pl-6 sm:pr-14"
                  )}>
                  <div
                    className={classNames(
                      "hidden h-12 w-12 flex-shrink-0 items-center justify-center self-start rounded-full sm:flex sm:h-16 sm:w-16 sm:self-start",
                      classBgColor
                    )}>
                    <Image
                      className={classNames("")}
                      src={item?.icon ?? dataDefault?.items?.[index]?.icon ?? "/images/icons/hands-green.svg"}
                      alt={item?.title ?? dataDefault?.items?.[index]?.title ?? ""}
                      width="34"
                      height="34"
                    />
                  </div>
                  <div>
                    <div className="flex flex-row gap-6">
                      <div
                        className={classNames(
                          "flex h-12 w-12 flex-shrink-0 items-center justify-center self-start rounded-full sm:hidden sm:h-16 sm:w-16 sm:self-start",
                          classBgColor
                        )}>
                        <Image
                          className={classNames("")}
                          src={
                            item?.icon ?? dataDefault?.items?.[index]?.icon ?? "/images/icons/hands-green.svg"
                          }
                          alt={item?.title ?? dataDefault?.items?.[index]?.title ?? ""}
                          width="34"
                          height="34"
                        />
                      </div>
                      <h3
                        className={classNames(
                          "w-full text-left text-lg font-semibold sm:text-2xl",
                          `${classTextColor}`
                        )}>
                        <Trans i18nKey={item?.title ?? ""} />
                      </h3>
                    </div>

                    <p className="mb-10 mt-2 text-base font-light sm:text-lg">
                      <Trans i18nKey={item?.description ?? ""} />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
