import { getCoordinates, getDistance } from "@/lib/api/apiGoogleMaps";
import { getProviders } from "@/lib/api/apiProvіders";
import { formatPhoneNumber } from "@/lib/helpers";
import { Provider, ProviderSearchResult, SearchProvider, TranslationsPDF } from "@/lib/types";
import { OfficeHours } from "@/lib/types";
import { MapCoordinates } from "@/lib/types/mapCoordinates";
import jsPDF from "jspdf";

export const generatePDF = async (
  searchProvidersAll: SearchProvider,
  translations: TranslationsPDF,
  phoneForQuestions: string,
  currentLocationAddress: string
) => {
  const pdf = new PDFGenerator(
    translations,
    623,
    792,
    searchProvidersAll,
    phoneForQuestions,
    currentLocationAddress
  );
  await pdf.generatePDF();
};

class PDFGenerator {
  private translations: TranslationsPDF;
  private width: number;
  private height: number;
  private searchCriteria: SearchProvider;
  private phoneForQuestions: string;
  private currentLocationAddress: string;

  private imageBase64s: string[] = [];
  private textRatio: number = 1;
  private xRatio: number = 1;
  private yRatio: number = 1;
  private providerSearchResult: ProviderSearchResult = {
    providers: [],
    total_records: 0,
    last_update: "",
  };
  private currentPage: number = 1;
  private spaceX: number = 35;
  private spaceY: number = 17;

  public constructor(
    translations: TranslationsPDF,
    width: number,
    height: number,
    searchProvidersAll: SearchProvider,
    phoneForQuestions: string,
    currentLocationAddress: string
  ) {
    this.translations = translations;
    this.width = width;
    this.height = height;
    this.searchCriteria = searchProvidersAll;
    this.phoneForQuestions = phoneForQuestions;
    this.currentLocationAddress = currentLocationAddress;
  }

  public async generatePDF() {
    await this.loadImages();

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [this.width, this.height],
      putOnlyUsedFonts: true,
      compress: true,
    });

    await this.loadFonts(doc);
    // const pageWidth = doc.internal.pageSize.getWidth();
    // const pageHeight = doc.internal.pageSize.getHeight();

    this.providerSearchResult = await this.searchProviders();
    this.firstPage(doc);
    if (this.providerSearchResult.providers.length) {
      await this.providersPage(doc);
    }
    doc.save("Provider_Search_Directory.pdf");
  }

  private firstPage(doc: jsPDF) {
    let positionX = this.spaceX;
    // Set the blue background for the header
    doc.setFillColor(232, 244, 250);
    doc.rect(0, 0, this.width / this.xRatio, 256 / this.yRatio, "F");

    doc.addImage(
      this.imageBase64s[0],
      "PNG",
      214 / this.xRatio,
      47 / this.yRatio,
      200 / this.xRatio,
      31 / this.yRatio
    );

    // Title
    doc.setFont("Avenir", "bold");
    doc.setTextColor(17, 55, 77);
    doc.setFontSize(36 / this.textRatio);
    doc.text(this.translations.title, this.width / 2 / this.xRatio, 120 / this.yRatio, { align: "center" });

    // Subtitle
    doc.setFont("Avenir", "normal");
    doc.setFontSize(18 / this.textRatio);
    doc.text(
      `${this.searchCriteria.limit} ${this.translations.subtitle}`,
      this.width / 2 / this.xRatio,
      166 / this.yRatio,
      { align: "center" }
    );
    doc.setFontSize(12 / this.textRatio);
    doc.text(
      `${this.translations.listGeneratedOn}: ${this.getGeneratedOnDate()}      ${this.translations.lastUpdate}: ${this.getLastUpdateDate(this.providerSearchResult.last_update)}`,
      this.width / 2 / this.xRatio,
      205 / this.yRatio,
      { align: "center" }
    );

    // Search Criteria for:
    const titleSpace = 66;
    doc.setDrawColor(199, 207, 212);
    doc.setFillColor(255, 255, 255);
    this.drawRoundedRect(doc, 34 / this.xRatio, 315 / this.yRatio, 543 / this.xRatio, 182 / this.yRatio, 8);

    let positionY = 305;
    doc.setFontSize(16 / this.textRatio);
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.searchCriteriaFor}: `, 34 / this.xRatio, positionY / this.yRatio);
    doc.setFontSize(12 / this.textRatio);

    positionY += 40;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.firstName}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.Name.substring(0, this.searchCriteria.Name.indexOf(" "))}`,
      titleSpace + doc.getTextWidth(`${this.translations.firstName}:`) + 3,
      positionY / this.yRatio
    );

    positionY += 26;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.lastName}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.Name.substring(this.searchCriteria.Name.indexOf(" ") + 1)}`,
      titleSpace + doc.getTextWidth(`${this.translations.lastName}:`) + 3,
      positionY / this.yRatio
    );

    positionY += 26;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.clinic}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.Clinic}`,
      titleSpace + doc.getTextWidth(`${this.translations.clinic}:`) + 3,
      positionY / this.yRatio
    );

    positionY += 26;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.category}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.speciality_1 ?? ""}`,
      titleSpace + doc.getTextWidth(`${this.translations.category}:`) + 3,
      positionY / this.yRatio
    );

    positionY += 26;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.distance}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.range} ${this.translations.miles}`,
      titleSpace + doc.getTextWidth(`${this.translations.distance}:`) + 3,
      positionY / this.yRatio
    );

    positionY += 26;
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.zipCode}:`, titleSpace / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    doc.text(
      `${this.searchCriteria.zip_code ?? ""}`,
      titleSpace + doc.getTextWidth(`${this.translations.zipCode}:`) + 3,
      positionY / this.yRatio
    );

    // About your search results:
    doc.addImage(
      this.imageBase64s[1],
      "PNG",
      positionX / this.xRatio,
      520 / this.yRatio,
      12 / this.xRatio,
      12 / this.yRatio
    );

    doc.setFontSize(10 / this.textRatio);
    doc.setFont("Avenir", "bold");
    doc.text(`${this.translations.aboutYourSearchResults}:`, 53 / this.xRatio, 530 / this.yRatio);
    doc.setFont("Avenir", "normal");
    const notes = [
      `${this.translations.thisListIsUpdatedDailyAndSubject}`,
      `${this.translations.yourResultsContainsProvidersWithOfficeLocations}`,
    ];

    positionY = 552;
    notes.forEach((note) => {
      const lines = doc.splitTextToSize(`• ${note}`, 504 / this.xRatio);
      lines.forEach((line: string) => {
        doc.text(line, 40 / this.xRatio, positionY / this.yRatio);
        positionY += 14;
      });
    });

    positionY += 12;
    doc.text(
      `${this.translations.ifYouHaveAnyQuestionsAboutAProvider}`,
      positionX / this.xRatio,
      positionY / this.yRatio
    );
    doc.setFont("Avenir", "bold");
    doc.text(
      this.phoneForQuestions,
      doc.getTextWidth(`${this.translations.ifYouHaveAnyQuestionsAboutAProvider}`) + positionX - 12,
      positionY / this.yRatio
    );

    // Footer
    doc.setFontSize(8 / this.textRatio);
    doc.setFont("Avenir", "bold");
    positionY = 720;
    doc.text(`${this.translations.termsOfUse}:`, positionX / this.xRatio, positionY / this.yRatio);
    doc.setFont("Avenir", "normal");
    const lines = doc.splitTextToSize(`${this.translations.theListOfProvidersWasPrinted}`, 504 / this.xRatio);
    lines.forEach((line: string, index: number) => {
      doc.text(
        line,
        (positionX + (index == 0 ? doc.getTextWidth(`${this.translations.termsOfUse}:`) + 4 : 0)) /
          this.xRatio,
        positionY / this.yRatio
      );
      positionY += 12;
    });

    this.addFooter(doc);
  }

  private async providersPage(doc: jsPDF) {
    const distances: string[] = await this.calculateDistance(this.providerSearchResult.providers);
    let positionX = 0;
    let positionY = 0;

    [positionX, positionY] = this.setNewColumnOrPade(
      doc,
      this.width,
      this.height,
      positionX,
      positionY,
      0,
      0
    );
    for (const provider of this.providerSearchResult.providers) {
      const providerIndex = this.providerSearchResult.providers.indexOf(provider);
      const firstLastNameWidth = doc.getTextWidth(
        `${provider.First_Name}${provider.First_Name != "" ? " " : ""}${provider.Last_Name}`
      );
      let blueRectHeight =
        provider.Accepting_New_Patients != "Y" &&
        provider.Network_ID == "" &&
        provider.Board_Certified.length == 0
          ? 65
          : provider.Accepting_New_Patients === "Y" &&
              (provider.Network_ID != "" || provider.Board_Certified.length > 0)
            ? 145
            : 105;
      if (firstLastNameWidth > 88) {
        blueRectHeight += (Math.ceil(firstLastNameWidth / 88) - 1) * 15;
      }
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        0,
        blueRectHeight
      );
      doc.setFillColor(232, 244, 250);
      doc.roundedRect(
        positionX / this.xRatio,
        positionY / this.yRatio,
        (this.width / 2 - 54) / this.xRatio,
        blueRectHeight / this.yRatio,
        13,
        13,
        "F"
      );
      doc.setFont("Avenir", "bold");
      doc.setFontSize(16 / this.textRatio);

      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        32,
        0
      );

      doc.setDrawColor(17, 55, 77);
      doc.setFillColor(255, 255, 255);
      doc.circle((positionX + 35) / this.xRatio, positionY / this.yRatio, 20, "FD");

      doc.setFontSize(18 / this.textRatio);
      doc.setTextColor(30, 165, 222);
      const initialsWidth = doc.getTextWidth(
        `${provider.First_Name.substring(0, 1).toUpperCase()}${provider.Last_Name.substring(0, 1).toUpperCase()}`
      );
      doc.text(
        `${provider.First_Name.substring(0, 1).toUpperCase()}${provider.Last_Name.substring(0, 1).toUpperCase()}`,
        (positionX + 15 + (40 - initialsWidth) / 2) / this.xRatio,
        (positionY + 6) / this.yRatio
      );
      doc.setFontSize(16 / this.textRatio);
      doc.setTextColor(17, 55, 77);
      // doc.text(`${provider.First_Name}${provider.First_Name != "" ? " " : ""}${provider.Last_Name}`, (positionX + 67) / this.xRatio, positionY - 2/ this.yRatio);
      const firstLastName = doc.splitTextToSize(
        `${provider.First_Name}${provider.First_Name != "" ? " " : ""}${provider.Last_Name}`,
        (this.width / 2 - 120) / this.xRatio
      );
      firstLastName.forEach((line: string, index: number) => {
        doc.text(line, (positionX + 67) / this.xRatio, positionY - 2 / this.yRatio);
        if (firstLastName.length > index + 1) positionY += 15;
      });

      doc.setFont("Avenir", "normal");
      doc.setFontSize(12 / this.textRatio);
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        12,
        0
      );
      doc.text(`${provider.Speciality.join(", ")}`, (positionX + 67) / this.xRatio, positionY / this.yRatio);
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        20,
        0
      );

      doc.setFontSize(10 / this.textRatio);
      if (provider.Accepting_New_Patients === "Y") {
        doc.setDrawColor(17, 55, 77);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect((positionX + 15) / this.xRatio, positionY / this.yRatio, 161, 28, 13, 13, "FD");
        doc.addImage(
          this.imageBase64s[2],
          "PNG",
          (positionX + 24) / this.xRatio,
          (positionY + 7.5) / this.yRatio,
          12 / this.xRatio,
          12 / this.yRatio
        );
        doc.text(
          `${this.translations.acceptingNewPatients}`,
          (positionX + 44) / this.xRatio,
          (positionY + 17) / this.yRatio
        );
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          38,
          0
        );
      }
      if (provider.Network_ID != "") {
        doc.setDrawColor(17, 55, 77);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect((positionX + 15) / this.xRatio, positionY / this.yRatio, 97, 28, 13, 13, "FD");
        doc.addImage(
          this.imageBase64s[2],
          "PNG",
          (positionX + 24) / this.xRatio,
          (positionY + 7.5) / this.yRatio,
          12 / this.xRatio,
          12 / this.yRatio
        );
        doc.text(
          `${this.translations.inNetwork}`,
          (positionX + 44) / this.xRatio,
          (positionY + 17) / this.yRatio
        );
      }
      if (provider.Board_Certified.length > 0) {
        const roundedRectPositionX = provider.Network_ID != "" ? positionX + 120 : positionX + 15;
        const addImagePositionX = provider.Network_ID != "" ? positionX + 130 : positionX + 24;
        const textPositionX = provider.Network_ID != "" ? positionX + 150 : positionX + 44;
        doc.setDrawColor(17, 55, 77);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect(roundedRectPositionX / this.xRatio, positionY / this.yRatio, 123, 28, 13, 13, "FD");
        doc.addImage(
          this.imageBase64s[2],
          "PNG",
          addImagePositionX / this.xRatio,
          (positionY + 7.5) / this.yRatio,
          12 / this.xRatio,
          12 / this.yRatio
        );
        doc.text(
          `${this.translations.boardCertified}`,
          textPositionX / this.xRatio,
          (positionY + 17) / this.yRatio
        );
      }
      if (
        (provider.Accepting_New_Patients != "Y" &&
          provider.Network_ID == "" &&
          provider.Board_Certified.length == 0) ||
        (provider.Accepting_New_Patients === "Y" &&
          provider.Network_ID == "" &&
          provider.Board_Certified.length == 0)
      ) {
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          15,
          0
        );
      } else {
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          60,
          0
        );
      }
      doc.setFontSize(12 / this.textRatio);

      // Languages
      doc.setFont("Avenir", "bold");
      doc.text(`${this.translations.languages}:`, positionX / this.xRatio, positionY / this.yRatio);
      doc.setFont("Avenir", "normal");
      let languages = "";
      provider.Language_Code.forEach((lang) => {
        if (lang.Language_Code_name != "") {
          languages += languages == "" ? lang.Language_Code_name : " ," + lang.Language_Code_name;
        }
      });
      const linesLanguages = doc.splitTextToSize(`${languages}`, this.width / 2 - 80);
      linesLanguages.forEach((line: string, index: number) => {
        doc.text(
          line,
          (positionX + (index === 0 ? doc.getTextWidth(`${this.translations.languages}:`) + 6 : 0)) /
            this.xRatio,
          positionY / this.yRatio
        );
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          14,
          0
        );
      });

      // Gender
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        5,
        0
      );
      doc.setFont("Avenir", "bold");
      doc.text(`${this.translations.gender}:`, positionX / this.xRatio, positionY / this.yRatio);
      doc.setFont("Avenir", "normal");
      if (provider.Gender == "F") {
        doc.text(
          `${this.translations.female}`,
          (positionX + doc.getTextWidth(`${this.translations.gender}:`) + 6) / this.xRatio,
          positionY / this.yRatio
        );
      } else if (provider.Gender == "M") {
        doc.text(
          ` ${this.translations.male}`,
          (positionX + doc.getTextWidth(`${this.translations.gender}:`) + 6) / this.xRatio,
          positionY / this.yRatio
        );
      }

      // Speciality
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        17,
        0
      );
      doc.setFont("Avenir", "bold");
      doc.text(`${this.translations.specialty}:`, positionX / this.xRatio, positionY / this.yRatio);
      doc.setFont("Avenir", "normal");
      const linesSpeciality = doc.splitTextToSize(`${provider.Speciality.join(", ")}`, this.width / 2 - 80);
      linesSpeciality.forEach((line: string, index: number) => {
        doc.text(
          line,
          (positionX + (index === 0 ? doc.getTextWidth(`${this.translations.specialty}:`) + 6 : 0)) /
            this.xRatio,
          positionY / this.yRatio
        );
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          14,
          0
        );
      });

      // Method of Tele-Health
      positionY += 5;
      doc.setFont("Avenir", "bold");
      doc.text(`${this.translations.methodOfTeleHealth}:`, positionX / this.xRatio, positionY / this.yRatio);
      doc.setFont("Avenir", "normal");
      const linesMethodOfTelehealth = doc.splitTextToSize(
        `${provider["Method of Telehealth"]}`,
        this.width / 2 - 80
      );
      linesMethodOfTelehealth.forEach((line: string, index: number) => {
        doc.text(
          line,
          (positionX +
            (index === 0 ? doc.getTextWidth(`${this.translations.methodOfTeleHealth}:`) + 10 : 0)) /
            this.xRatio,
          positionY / this.yRatio
        );
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          14,
          0
        );
      });

      // Office
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        20,
        0
      );
      doc.setFontSize(16 / this.textRatio);
      doc.setFont("Avenir", "bold");
      doc.text(`${this.translations.office}`, positionX / this.xRatio, positionY / this.yRatio);

      if (distances.length && distances[providerIndex] != "") {
        const miles = `${distances[providerIndex].replaceAll("mi", "")} ${this.translations.milesAway}`;
        doc.setFillColor(232, 244, 250);
        doc.roundedRect(
          ((positionX == this.spaceX ? this.width / 2 : this.width) - doc.getTextWidth(miles) * 0.95) /
            this.xRatio,
          (positionY - 15) / this.yRatio,
          (doc.getTextWidth(miles) * 0.8) / this.xRatio,
          20 / this.yRatio,
          13,
          13,
          "F"
        );
        doc.setFontSize(10 / this.textRatio);
        doc.setFont("Avenir", "normal");
        doc.text(
          miles,
          ((positionX == this.spaceX ? this.width / 2 : this.width) -
            this.spaceX / 2 -
            doc.getTextWidth(miles) * 1.1) /
            this.xRatio,
          (positionY - 2) / this.yRatio
        );
      }

      // Address
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        10,
        25
      );
      doc.setFontSize(12 / this.textRatio);
      doc.addImage(
        this.imageBase64s[3],
        "PNG",
        positionX / this.xRatio,
        positionY / this.yRatio,
        25 / this.xRatio,
        25 / this.yRatio
      );
      doc.text(
        `${provider.Address.Address_1}`,
        (positionX + 40) / this.xRatio,
        (positionY + 10) / this.yRatio
      );
      doc.text(
        `${provider.Address.City}, ${provider.Address.State}  ${provider.Address.Zip}`,
        (positionX + 40) / this.xRatio,
        (positionY + 25) / this.yRatio
      );

      // Phone
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        40,
        24
      );
      doc.setFont("Avenir", "bold");
      doc.addImage(
        this.imageBase64s[4],
        "PNG",
        positionX / this.xRatio,
        positionY / this.yRatio,
        25 / this.xRatio,
        25 / this.yRatio
      );
      doc.text(
        `${formatPhoneNumber(provider.Phone_Number)}`,
        (positionX + 40) / this.xRatio,
        (positionY + 15) / this.yRatio
      );

      // Available times
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        45,
        0
      );
      doc.text(`${this.translations.availableTimes}`, positionX / this.xRatio, positionY / this.yRatio);

      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        15,
        0
      );
      doc.setFontSize(10 / this.textRatio);
      doc.setFont("Avenir", "normal");
      const isAllDaysNotAvailable: boolean = await this.areAllDaysNotAvailable(provider.office_hours);
      if (isAllDaysNotAvailable) {
        doc.text(
          `${this.translations.officeHrsNotReported}`,
          positionX / this.xRatio,
          positionY / this.yRatio
        );
        [positionX, positionY] = this.setNewColumnOrPade(
          doc,
          this.width,
          this.height,
          positionX,
          positionY,
          15,
          0
        );
      } else {
        Object.keys(provider.office_hours).forEach((day: string, index) => {
          doc.text(`${day}:`, positionX / this.xRatio, positionY / this.yRatio);
          doc.text(
            `${Object.values(provider.office_hours)[index].start_time}`,
            (positionX + doc.getTextWidth(day) + 6) / this.xRatio,
            positionY / this.yRatio
          );
          doc.text(
            `${Object.values(provider.office_hours)[index].end_time != "" ? " - " : ""}`,
            (positionX +
              doc.getTextWidth(day) +
              6 +
              doc.getTextWidth(`${Object.values(provider.office_hours)[index].start_time}`)) /
              this.xRatio,
            positionY / this.yRatio
          );
          doc.text(
            `${Object.values(provider.office_hours)[index].end_time}`,
            (positionX +
              doc.getTextWidth(day) +
              6 +
              doc.getTextWidth(`${Object.values(provider.office_hours)[index].start_time}`) +
              doc.getTextWidth(
                `${Object.values(provider.office_hours)[index].end_time != "" ? " - " : ""}`
              )) /
              this.xRatio,
            positionY / this.yRatio
          );
          [positionX, positionY] = this.setNewColumnOrPade(
            doc,
            this.width,
            this.height,
            positionX,
            positionY,
            15,
            0
          );
        });
      }
      doc.setDrawColor(199, 207, 212);
      doc.line(
        positionX,
        positionY / this.yRatio,
        (positionX == this.spaceX ? this.width / 2 : this.width) - this.spaceX / 2,
        positionY / this.yRatio
      );
      [positionX, positionY] = this.setNewColumnOrPade(
        doc,
        this.width,
        this.height,
        positionX,
        positionY,
        15,
        0
      );
    }
  }

  private addFooter(doc: jsPDF) {
    doc.setFont("Avenir", "bold");
    doc.setFontSize(8 / this.textRatio);
    doc.text(
      `©${new Date().getFullYear()} ${this.translations.allRightsReserved}`,
      35 / this.xRatio,
      757 / this.yRatio
    );
    doc.text(this.currentPage.toString(), 585, 760 / this.yRatio); // Page number
  }

  private setNewColumnOrPade = (
    doc: jsPDF,
    width: number,
    height: number,
    positionX: number,
    positionY: number,
    shiftY: number,
    shiftHeightY: number
  ) => {
    const contentPageEndY = height - 60;
    const blockStartY = positionY + shiftY;
    const blockEndY = blockStartY + shiftHeightY;
    if (
      positionY === 0 ||
      ((blockStartY > contentPageEndY || blockEndY > contentPageEndY) && positionX > 35)
    ) {
      positionX = this.spaceX;
      positionY = this.spaceY;
      if (positionY > 0) {
        const currentFont = doc.getFont();
        const fontSize: number = doc.getFontSize();
        this.addFooter(doc);
        doc.addPage();
        doc.setFont(currentFont.fontName, currentFont.fontStyle);
        doc.setFontSize(fontSize);
        this.currentPage += 1;
        doc.setDrawColor(199, 207, 212);
        doc.line(width / 2, 29, width / 2, height - 29);
      }
    } else if ((blockStartY > contentPageEndY || blockEndY > contentPageEndY) && positionX == 35) {
      positionX += width / 2;
      positionY = this.spaceY * 2;
    } else {
      positionY += shiftY;
    }

    return [positionX, positionY];
  };

  private drawRoundedRect = (doc: jsPDF, x: number, y: number, w: number, h: number, r: number) => {
    doc.setLineWidth(0.5);
    // doc.setDrawColor(199, 207, 212); // Red color for the border
    // doc.setFillColor(255, 255, 255); // Light grey color for the fill

    doc.ellipse(x + r, y + r, r, r, "S"); // Top-left corner
    doc.ellipse(x + w - r, y + r, r, r, "S"); // Top-right corner
    doc.ellipse(x + r, y + h - r, r, r, "S"); // Bottom-left corner
    doc.ellipse(x + w - r, y + h - r, r, r, "S"); // Bottom-right corner

    // Draw the edges
    doc.rect(x + r, y, w - 2 * r, h, "F"); // Top edge
    doc.rect(x, y + r, w, h - 2 * r, "F"); // Middle (left and right edges)
    doc.rect(x + r, y + h - r, w - 2 * r, r, "F"); // Bottom edge

    // Draw the border
    doc.line(x + r, y, x + w - r, y); // Top line
    doc.line(x + r, y + h, x + w - r, y + h); // Bottom line
    doc.line(x, y + r, x, y + h - r); // Left line
    doc.line(x + w, y + r, x + w, y + h - r); // Right line

    doc.stroke();
    doc.fill();
  };

  private getGeneratedOnDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: string = (today.getMonth() + 1).toString(); // Months start at 0!
    let dd: string = today.getDate().toString();

    if (parseInt(dd) < 10) dd = "0" + dd;
    if (parseInt(mm) < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;
    return formattedToday;
  };

  private getLastUpdateDate = (lastUpdatedDate: string) => {
    const date = new Date(lastUpdatedDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  private async searchProviders(): Promise<ProviderSearchResult> {
    const providerSearchResult: ProviderSearchResult = await getProviders(this.searchCriteria);
    return providerSearchResult;
  }

  private async calculateDistance(providers: Provider[]): Promise<string[]> {
    let distances: string[] = [];
    if (this.currentLocationAddress != "") {
      const addresses: string[] = providers.map((provider) => {
        const address = provider.Address;
        return `${address.Address_1}, ${address.City}, ${address.State} ${address.Zip}`;
      });
      const coordinates: MapCoordinates[] = await getCoordinates(addresses);
      distances = await Promise.all(
        coordinates.map((coordinate) => getDistance(this.currentLocationAddress, coordinate.address))
      );
    }
    return distances;
  }

  async loadImages() {
    const imageUrls = [
      "/images/SimpleTherapy.png",
      "/images/pdf/info.png",
      "/images/pdf/check-mark.png",
      "/images/pdf/mark.png",
      "/images/pdf/phone.png",
    ];
    const images = await Promise.all(imageUrls.map((url) => fetch(url).then((response) => response.blob())));
    const readers: Promise<string>[] = images.map((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });

    this.imageBase64s = await Promise.all(readers);
  }

  private async loadFonts(doc: jsPDF) {
    const AvenirRegularBase64 = await fetch(window.location.origin + "/fonts/Avenir-Medium-base64.txt").then(
      (r) => r.text()
    );
    const AvenirBoldBase64 = await fetch(window.location.origin + "/fonts/Avenir-Black-base64.txt").then(
      (r) => r.text()
    );
    const AvenirItalicBase64 = await fetch(
      window.location.origin + "/fonts/Avenir-MediumOblique-base64.txt"
    ).then((r) => r.text());
    const AvenirBoldItalicBase64 = await fetch(
      window.location.origin + "/fonts/Avenir-BlackOblique-base64.txt"
    ).then((r) => r.text());

    // Add custom fonts to jsPDF
    doc.addFileToVFS("Avenir-Medium.ttf", AvenirRegularBase64);
    doc.addFont("Avenir-Medium.ttf", "Avenir", "normal");

    doc.addFileToVFS("Avenir-Black.ttf", AvenirBoldBase64);
    doc.addFont("Avenir-Black.ttf", "Avenir", "bold");

    doc.addFileToVFS("Avenir-MediumOblique.ttf", AvenirItalicBase64);
    doc.addFont("Avenir-MediumOblique.ttf", "Avenir", "italic");
    //
    doc.addFileToVFS("Avenir-BlackOblique.ttf", AvenirBoldItalicBase64);
    doc.addFont("Avenir-BlackOblique.ttf", "Avenir", "bolditalic");
  }

  private async areAllDaysNotAvailable(schedule: OfficeHours): Promise<boolean> {
    return Object.values(schedule).every((day) => day.start_time === "NOT AVAILABLE");
  }
}
