import classNames from "classnames";
import { Trans } from "next-i18next";
import React, { useEffect, useState } from "react";
import { LuChevronDown } from "react-icons/lu";

export const Accordion = ({
  className,
  title,
  children,
  classTextColor,
  classStrokeColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  classTextColor: string | undefined;
  classStrokeColor: string | undefined;
}) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

  useEffect(() => {
    setAccordionOpen(false);
  }, []);

  return (
    <div className={classNames("", className ?? "")} {...rest}>
      <h2>
        <button
          className="flex w-full items-center justify-between py-2 text-left font-semibold"
          onClick={(e) => {
            e.preventDefault();
            setAccordionOpen(!accordionOpen);
          }}
          aria-expanded={accordionOpen}
          aria-controls="accordion-text-01">
          <span>
            <h3
              className={classNames(
                "whitespace-pre-line py-2 text-left text-lg font-medium",
                classTextColor
              )}>
              <Trans i18nKey={title} />
            </h3>
          </span>
          <LuChevronDown
            className={classNames(
              "float-right ml-2.5 transition-all duration-300",
              classStrokeColor,
              accordionOpen && "!rotate-180"
            )}
          />
        </button>
      </h2>
      <div
        id="accordion-text-01"
        role="region"
        aria-labelledby="accordion-title-01"
        className={`grid overflow-hidden text-sm text-slate-600 transition-all duration-300 ease-in-out ${accordionOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"}`}>
        <div className={classNames("overflow-hidden")}>{children}</div>
      </div>
    </div>
  );
};
